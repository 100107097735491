import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {Ride} from '../../../../../../../models/ride';
import {ActivatedRoute} from '@angular/router';
import {CompanyWebUserService} from '../../../../../../../services/companyWebUser.service';
import {Company} from '../../../../../../../models/company';
import {CoolLocalStorage} from '@angular-cool/storage';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RideService} from '../../../../../../../services/ride.service';

@Component({
  selector: 'app-forward-ride',
  templateUrl: './forward-ride.component.html',
  styleUrls: ['./forward-ride.component.scss']
})
export class ForwardRideComponent implements OnInit {
  @Input() parent: any;
  @Input() ride: Ride;
  @Input() company: any;
  @Output() reloadData = new EventEmitter<string>();
  @Output() stopDispatch = new EventEmitter<string>();
  @Output() closeDialog = new EventEmitter<string>();
  @Output() reloadTab = new EventEmitter<string>();

  myCompanies = [];
  selected: any;
  companyWebUser: any;
  myData: any;
  translations: String[];
  parentData: any;
  context = 'company';
  selectedCompany: Company;

  constructor(
    private _route: ActivatedRoute,
    private _companyWebUserService: CompanyWebUserService,
    private _rideService: RideService,
    public _dialogRef: MatDialogRef<ForwardRideComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    if (this.parent) {
      this.context = this.parent.context;
    } else {
      this.context = this._route.routeConfig['context'] || 'driver';
    }

    this.loadCompanies();

    if (this.company) {
      this.selected = this.company.id;
    }
  }

  loadCompanies(): void {
    const self = this;
    this.myCompanies = [];
    this._companyWebUserService.getCompanies().subscribe((companyWebUser: any[]) => {
      if (companyWebUser.length > 0) {
        companyWebUser.forEach((c) => {
          if (c.Company) {
            self.myCompanies.push(c.Company);
          }
        });
      }

      // remove duplicates from self.MyCompanies by id of the company
      self.myCompanies = self.myCompanies.filter((c, index) => {
        return self.myCompanies.findIndex((c2) => {
          return c.id === c2.id;
        }) === index
      })

      //Remove current login company from mycompanies
      self.myCompanies = self.myCompanies.filter((c) => {
        return c.id !== self.company.id;
      });

      // self.companyWebUser = companyWebUser[0];
      self.selected = self.myCompanies[0].id;
      this.selectedCompany = self.myCompanies[0];
      console.log(this.selectedCompany);
    });
  }

  selectGroup(event) {
    if (event.value) {
      const selected = this.myCompanies.filter((c) => {
        return (c.id === event.value)
      });
      if (selected.length > 0) {
        this.selectedCompany = {
          id: selected[0].id,
          name: selected[0].name
        };
      } else {
        this.selectedCompany = null;
      }
    } else {
      this.selectedCompany = null;
    }
  }

  getSelectedIcon() {
    return (this.selectedCompany ? 'business' : 'personal');
  }

  getCompanyName() {
    return (this.selectedCompany ? this.selectedCompany.name : '');
  }

  reloadDriverData(): void {
    this.reloadData.next();
  }

  forwardRide(): void {
    const self = this;
    // Forward ride to selected company
    self._rideService.forwardRide(this.ride.id, this.selectedCompany.id, 'company').subscribe(() => {
      self.reloadTab.emit();
      if (self._dialogRef && self._dialogRef.close) {
        self._dialogRef.close();
      }
    })
  }
}
