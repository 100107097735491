<app-no-contract *ngIf="this.company &&
(this.context==='company' &&
(!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)) || this.company.ActiveContract.type
 === 'webbooker_license')" [companyId]="this.companyId"
                 [contract]="this.company.ActiveContract"
                 [type]="'dispatchpanel'"></app-no-contract>
<mat-card [class]="(this.company &&
(this.context==='company' &&
(!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)) || this.company.ActiveContract.type
 === 'webbooker_license') ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>

  <div [@multiSelect]="multiSelectedState" class="multi-select-buttons">
    <span class="push-right">{{ multiSelect.length }}</span>

    <button (click)="assignMultiJobDialog()" *ngIf="this.activeTab==='unassigned'" color="accent"
            mat-raised-button>{{ 'assign_job_all_selected' | translate | ucFirst }}
    </button>

    <button (click)="multiAction('unassign')" *ngIf="this.activeTab==='todo'" color="accent"
            mat-raised-button>{{ 'unassign_job_all_selected' | translate | ucFirst }}
    </button>

    <button (click)="completeMultiJobDialog()" color="accent"
            mat-raised-button>{{ 'complete_job_all_selected' | translate | ucFirst }}
    </button>

    <button (click)="cancelMultiJob()" color="warn"
            mat-raised-button>{{ 'cancel_job_all_selected' | translate | ucFirst }}
    </button>

    <mat-divider *ngIf="multiSelectedState==='expanded'"></mat-divider>
  </div>

  <ng-template tdLoading="rides.list">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex" animationDuration="0ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">access_time</mat-icon>
          {{ 'to_assign_jobs'|translate }} ({{ (unassignedFilteredTotal ? unassignedFilteredTotal : '0') }})
        </ng-template>
        <table class="mat-clickable dispatchpanel-table" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="unassignedSort($event)"
                *ngFor="let c of unassignedColumns"
                [active]="(unassignedSortBy == c.name)"
                [name]="c.name"
                [numeric]="(!!c.numeric)"
                [sortOrder]="(unassignedSortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="(!!c.sortable)"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}} {{c.maxWidth}}"
                style="white-space:nowrap"
                td-data-table-column>
                <span *ngIf="c.name === 'check'">
                  <mat-checkbox
                    (ngModelChange)="toggleMultiActionAll()"
                    [(ngModel)]="allSelected">
                    </mat-checkbox>
                </span>
              <span *ngIf="c.name !== 'check'">
                  {{ translations[c.label]|ucFirst }}
                </span>
            </th>
          </tr>
          </thead>
          <tbody *ngIf="unassignedFilteredData.length > 0" class="assign-tab">
          <ng-container *ngFor="let item of unassignedFilteredData;" class="todo-assign">
            <tr class="pointer {{item.colorClass}}" td-data-table-row>
              <td (click)="(c.name !== 'actions' &&
                c.name !== 'check' &&
                ( c.name !== 'status' ||
                  (!item.LastOffer || (item.LastOffer && item.LastOffer.type !== 'sharedToGroup' && item.LastOffer.offerType !== 'failed'))
                ) ? (toggleDetails(item.id)) : '')" (dblclick)="toggleDetails(item.id)"
                  *ngFor="let c of unassignedColumns"
                  [numeric]="c.numeric"
                  class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td"
                  td-data-table-cell>
                    <span [ngSwitch]="c.name">
                      <span *ngSwitchCase="'check'" class="dispatchpanel-checkbox">
                        <mat-checkbox
                          [(ngModel)]="multiSelectBox[item.id]"
                          (ngModelChange)="(hasContract ? toggleMultiAction() : showAlert())"
                          [value]="item.id">
                        </mat-checkbox>
                      </span>

                      <span *ngSwitchCase="'reference'" class="bold" matTooltip="{{item.tooltip}}">
                        <div class="color-coting">
                          <div *ngIf="item.priceMethod==='hourlyFixed' || item.priceMethod==='hourlyMeter'"
                               class="order-type"></div>
                          <div *ngFor="let metaItem of item.metaColor" class="meta-field-{{metaItem}}"></div>
                        </div>
                        {{ item[c.name] }}
                      </span>

                      <span *ngSwitchCase="'requestedDate' || 'dispatchTimeToPickup'">
                        <span
                          *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span>
                      </span>

                      <span *ngSwitchCase="'departure'" class="wrap-line departure-column">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item.departure}}">{{ item.departure }}
                        </span>
                        <div class="stop-over-position">
                        <mat-icon *ngIf="item['stopOvers'].length > 0" mat-list-icon class="stopover-icon"
                                  [matTooltip]="item['stopOverToolTip']" [matBadge]="item['stopOvers'].length"
                                  matBadgeSize="small" matBadgePosition="above after"

                                  matTooltipClass="new-lines">location_on</mat-icon>
                          </div>
                        <span *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'originName'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item[c.name]}}">{{ item[c.name] }}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'destination'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item.destination}}">{{ item.destination }}</span>
                        <span *ngIf="!item[c.name]">-</span></span>

                      <span *ngSwitchCase="'status'">
                        <span [ngSwitch]="item.offerType">
                          <span (click)="(hasContract ? openDispatchLinkDialog((item.LastOffer ? item.LastOffer : ''),
                                item) : showAlert())" *ngSwitchCase="'sharedToGroup'"
                                class="wrap-line dispatch-active">{{ item.statusTxt }}</span>
                          <span *ngSwitchCase="'company'" class="wrap-line dispatch-active">
                            <span class="dispatch-active-text">{{ item.statusTxt }}</span>
                            <div class="snippet" data-title=".dot-pulse">
                              <div class="stage">
                                <div class="dot-pulse"></div>
                              </div>
                            </div>
                          </span>
                          <span (click)="openDispatchLog(item.id);" *ngSwitchCase="'failed'"
                                class="wrap-line dispatch-active tc-red-600">
                            {{ item.statusTxt | ucFirst }}
                          </span>
                          <span *ngSwitchDefault class="wrap-line">{{ item.statusTxt }}</span>
                        </span>
                      </span>
                      <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name] }}</span><span
                        *ngIf="!item[c.name]">-</span></span>
                      <span *ngSwitchCase="'actions'" class="wrap-line">
                        <button [matMenuTriggerFor]="menu" mat-icon-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button (click)="copyRide(item.id)"
                                  mat-menu-item>
                            <mat-icon class="icon-right">file_copy</mat-icon>
                            <span>{{ 'copy_ride' | translate | ucFirst }}
                              {{ item['copy_ride'] }}</span>
                          </button>

                          <button (click)="copyRide(item.id, 'return')"
                                  mat-menu-item>
                            <mat-icon class="icon-right">compare_arrows</mat-icon>
                            <span>{{ 'return_ride' | translate | ucFirst }}
                              {{ item['return_ride'] }}</span>
                          </button>

                          <button (click)="resendConfirmation(item.id, item['passengerEmail'])"
                                  [disabled]="!item['passengerEmail']"
                                  mat-menu-item>
                            <mat-icon class="icon-right">mail</mat-icon>
                            <span>{{ (!item['passengerEmail'] ? 'resend_no_email' : 'resend_confirmation_email') | translate | ucFirst }}
                              {{ item['passengerEmail'] }}</span>
                          </button>
                          <button (click)="addPassengerAsContact(item)" *ngIf="item['allowedPassengerAdd']"
                                  [disabled]="!item['passengerEmail']"
                                  mat-menu-item>
                            <mat-icon class="icon-right">save</mat-icon>
                            <span>
                              {{ 'save_passenger_as_contact' | translate | ucFirst }}
                            </span>
                          </button>
                          <button (click)="completeJob(item.id)" *ngIf="!['completed','canceled'].includes(item.status)"
                                  mat-menu-item>
                            <mat-icon class="icon-right">done</mat-icon>
                            <span>{{ 'complete_job' | translate | ucFirst }}</span>
                          </button>
                        </mat-menu>
                      </span>
                    </span>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}}">
              <td colspan="15">
                <app-dispatchpanel-details
                  *ngIf="this.jobDetails[item.id] === 'expanded'"
                  [activeTab]="activeTab"
                  [companyId]="companyId"
                  [company]="company"
                  [context]="context" [item]="item" [jobDetails]="jobDetails"
                  [parent]="this"
                  [translations]="translations"></app-dispatchpanel-details>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}} shadow-border">
              <td class="button_row" colspan="15">
                <app-dispatchpanel-actions
                  [activeTab]="activeTab" [companyId]="companyId"
                  [company]="company"
                  [context]="context"
                  [hasContract]="hasContract" [item]="item" [jobDetails]="jobDetails"
                  [parent]="this" [translations]="translations"></app-dispatchpanel-actions>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="unassignedFilteredData.length === 0">
          <tr>
            <td [colSpan]="unassignedColumns.length"
                class="table-placeholder">
              {{ 'no_jobs_in_overview'|translate|ucFirst }}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #unassignedPagingBar (change)="unassignedPage($event)" [pageSize]="unassignedPageSize"
                       [total]="unassignedFilteredTotal">
          <span hide-xs>{{ 'rows_p_page'|translate }}:</span>
          {{ unassignedPagingBar.range }} <span hide-xs>of {{ unassignedPagingBar.total }}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">access_time</mat-icon>
          {{ 'todo_jobs'|translate }} ({{ (todoFilteredTotal ? todoFilteredTotal : '0') }})
        </ng-template>
        <table class="mat-clickable dispatchpanel-table" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="todoSort($event)"
                *ngFor="let c of todoColumns"
                [active]="(todoSortBy == c.name)"
                [name]="c.name"
                [numeric]="(!!c.numeric)"
                [sortOrder]="(todoSortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="(!!c.sortable)"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}} {{c.maxWidth}}"
                td-data-table-column>
                <span *ngIf="c.name === 'check'">
                  <mat-checkbox
                    (ngModelChange)="toggleMultiActionAll()"
                    [(ngModel)]="allSelected">
                  </mat-checkbox>
                </span>
              <span *ngIf="c.name !== 'check'">
                  {{ translations[c.label]|ucFirst }}
                </span>
            </th>
          </tr>
          </thead>
          <tbody *ngIf="todoFilteredData.length > 0" class="todo-tab">
          <ng-container *ngFor="let item of todoFilteredData;">
            <tr class="pointer {{item.colorClass}}" td-data-table-row>
              <td (click)="(c.name !== 'actions' &&
                c.name !== 'check' &&
                ( c.name !== 'status' ||
                  (!item.LastOffer || (item.LastOffer && item.LastOffer.type !== 'sharedToGroup' && item.LastOffer.offerType !== 'failed'))
                ) ? toggleDetails(item.id) : '')" *ngFor="let c of todoColumns" [numeric]="c.numeric"
                  class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td"
                  td-data-table-cell>
                    <span [ngSwitch]="c.name">
                      <span *ngSwitchCase="'check'">
                        <mat-checkbox
                          (ngModelChange)="toggleMultiAction()"
                          [(ngModel)]="multiSelectBox[item.id]"
                          [value]="item.id">
                        </mat-checkbox>
                      </span>

                      <span *ngSwitchCase="'reference'" matTooltip="{{item.tooltip}}">
                        <div class="color-coting">
                          <div *ngIf="item.priceMethod==='hourlyFixed'"
                               class="order-type"></div>
                          <div *ngFor="let metaItem of item.metaColor" class="meta-field-{{metaItem}}"></div>
                        </div>
                        {{ item[c.name] }}
                      </span>

                      <span *ngSwitchCase="'requestedDate' || 'dispatchTimeToPickup'">
                        <span
                          *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span>
                      </span>
                      <span *ngSwitchCase="'originName'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item[c.name]}}">{{ item[c.name] }}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'departure'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item.departure}}">{{ item.departure }}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'destination'" class="wrap-line">
                        <span *ngIf="item[c.name]" title="{{item.destination}}">{{ item.destination }}</span>
                        <span *ngIf="!item[c.name]">-</span></span>
                      <span *ngSwitchCase="'status'">
                        <span [ngSwitch]="item.offerType">
                          <span (click)="openDispatchLinkDialog((item.LastOffer ? item.LastOffer : ''), item)"
                                *ngSwitchCase="'sharedToGroup'"
                                class="wrap-line dispatch-active">{{ item.statusTxt }}</span>
                          <span *ngSwitchCase="'company'" class="wrap-line dispatch-active">
                            <span class="dispatch-active-text">{{ item.statusTxt }}</span>
                            <div class="snippet" data-title=".dot-pulse">
                              <div class="stage">
                                <div class="dot-pulse"></div>
                              </div>
                            </div>
                          </span>
                          <span (click)="openDispatchLog(item.id);" *ngSwitchCase="'failed'"
                                class="wrap-line dispatch-active tc-red-600">
                            {{ item.statusTxt | ucFirst }}
                          </span>
                          <span *ngSwitchDefault class="wrap-line">{{ item.statusTxt }}</span>
                        </span>
                      </span>
                      <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name] }}</span><span
                        *ngIf="!item[c.name]">-</span></span>
                      <span *ngSwitchCase="'actions'" class="wrap-line">
                        <button [matMenuTriggerFor]="menu" mat-icon-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button (click)="copyRide(item.id)"
                                  mat-menu-item>
                            <mat-icon class="icon-right">file_copy</mat-icon>
                            <span>{{ 'copy_ride' | translate | ucFirst }}
                              {{ item['copy_ride'] }}</span>
                          </button>

                          <button (click)="copyRide(item.id, 'return')"
                                  mat-menu-item>
                            <mat-icon class="icon-right">compare_arrows</mat-icon>
                            <span>{{ 'return_ride' | translate | ucFirst }}
                              {{ item['return_ride'] }}</span>
                          </button>

                          <button (click)="resendConfirmation(item.id, item['passengerEmail'])"
                                  [disabled]="!item['passengerEmail']"
                                  mat-menu-item>
                            <mat-icon class="icon-right">mail</mat-icon>
                            <span>{{ (!item['passengerEmail'] ? 'resend_no_email' : 'resend_confirmation_email') | translate | ucFirst }}
                              {{ item['passengerEmail'] }}</span>
                          </button>
                          <button (click)="completeJob(item.id)" *ngIf="!['completed','canceled'].includes(item.status)"
                                  mat-menu-item>
                            <mat-icon class="icon-right">done</mat-icon>
                            <span>{{ 'complete_job' | translate | ucFirst }}</span>
                          </button>
                        </mat-menu>
                      </span>
                    </span>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}}">
              <td colspan="15">
                <app-dispatchpanel-details *ngIf="this.jobDetails[item.id] === 'expanded'"
                                           [activeTab]="activeTab" [companyId]="companyId"
                                           [company]="company"
                                           [context]="context" [item]="item" [jobDetails]="jobDetails"
                                           [parent]="this" [translations]="translations"></app-dispatchpanel-details>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}} shadow-border">
              <td class="button_row" colspan="15">
                <app-dispatchpanel-actions
                  [activeTab]="activeTab" [companyId]="companyId"
                  [company]="company"
                  [context]="context"
                  [hasContract]="hasContract" [item]="item" [jobDetails]="jobDetails"
                  [parent]="this" [translations]="translations"></app-dispatchpanel-actions>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="todoFilteredData.length === 0">
          <tr>
            <td [colSpan]="todoColumns.length"
                class="table-placeholder">
              {{ 'no_jobs_in_overview'|translate|ucFirst }}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #todoPagingBar (change)="todoPage($event)" [pageSize]="todoPageSize"
                       [total]="todoFilteredTotal">
          <span hide-xs>{{ 'rows_p_page'|translate }}:</span>
          {{ todoPagingBar.range }} <span hide-xs>of {{ todoPagingBar.total }}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">check</mat-icon>
          {{ 'completed_jobs'|translate }} ({{ (completedFilteredTotal ? completedFilteredTotal : '0') }})
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="completedSort($event)"
                *ngFor="let c of completedColumns"
                [active]="(completedSortBy == c.name)"
                [name]="c.name"
                [numeric]="(!!c.numeric)"
                [sortOrder]="(completedSortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="(!!c.sortable)"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}} {{c.maxWidth}}"
                td-data-table-column>
              {{ c.label|translate|ucFirst }}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="completedFilteredData.length > 0">
          <ng-container *ngFor="let item of completedFilteredData;">
            <tr class="pointer {{item.colorClass}}" td-data-table-row>
              <td (click)="(c.name !== 'actions' &&
                c.name !== 'check' &&
                ( c.name !== 'status' ||
                  (!item.LastOffer || (item.LastOffer && item.LastOffer.type !== 'sharedToGroup' && item.LastOffer.offerType !== 'failed'))
                ) ? toggleDetails(item.id) : '')" *ngFor="let c of completedColumns" [numeric]="c.numeric"
                  class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td"
                  td-data-table-cell>
                    <span [ngSwitch]="c.name">
                      <span *ngSwitchCase="'check'">
                        <mat-checkbox
                          (ngModelChange)="toggleMultiAction()"
                          [(ngModel)]="multiSelectBox[item.id]"
                          [value]="item.id">
                        </mat-checkbox>
                      </span>

                      <span *ngSwitchCase="'reference'" matTooltip="{{item.tooltip}}">
                        <div class="color-coting">
                          <div *ngIf="item.priceMethod==='hourlyFixed'"
                               class="order-type"></div>
                          <div *ngFor="let metaItem of item.metaColor" class="meta-field-{{metaItem}}"></div>
                        </div>
                        {{ item[c.name] }}
                      </span>

                      <span *ngSwitchCase="'requestedDate' || 'dispatchTimeToPickup'">
                        <span
                          *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span>
                      </span>

                      <span *ngSwitchCase="'originName'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item[c.name]}}">{{ item[c.name] }}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'departure'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item.departure}}">{{ item.departure }}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'destination'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item.destination}}">{{ item.destination }}</span>
                        <span *ngIf="!item[c.name]">-</span></span>
                      <span *ngSwitchCase="'status'">
                        <span [ngSwitch]="item.offerType">
                          <span (click)="openDispatchLinkDialog((item.LastOffer ? item.LastOffer : ''), item)"
                                *ngSwitchCase="'sharedToGroup'"
                                class="wrap-line dispatch-active">{{ item.statusTxt }}</span>
                          <span *ngSwitchCase="'company'" class="wrap-line dispatch-active">
                            <span class="dispatch-active-text">{{ item.statusTxt }}</span>
                            <div class="snippet" data-title=".dot-pulse">
                              <div class="stage">
                                <div class="dot-pulse"></div>
                              </div>
                            </div>
                          </span>
                          <span (click)="openDispatchLog(item.id);" *ngSwitchCase="'failed'"
                                class="wrap-line dispatch-active tc-red-600">
                            {{ item.statusTxt | ucFirst }}
                          </span>
                          <span *ngSwitchDefault class="wrap-line">{{ item.statusTxt }}</span>
                        </span>
                      </span>
                      <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name] }}</span><span
                        *ngIf="!item[c.name]">-</span></span>
                      <span *ngSwitchCase="'actions'" class="wrap-line">
                        <button [matMenuTriggerFor]="menu" mat-icon-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button (click)="copyRide(item.id)"
                                  mat-menu-item>
                            <mat-icon class="icon-right">file_copy</mat-icon>
                            <span>{{ 'copy_ride' | translate | ucFirst }}
                              {{ item['copy_ride'] }}</span>
                          </button>

                          <button (click)="copyRide(item.id, 'return')"
                                  mat-menu-item>
                            <mat-icon class="icon-right">compare_arrows</mat-icon>
                            <span>{{ 'return_ride' | translate | ucFirst }}
                              {{ item['return_ride'] }}</span>
                          </button>
                        </mat-menu>
                      </span>
                    </span>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}}">
              <td colspan="15">
                <app-dispatchpanel-details *ngIf="this.jobDetails[item.id] === 'expanded'"
                                           [activeTab]="activeTab"
                                           [companyId]="companyId"
                                           [company]="company"
                                           [context]="context" [item]="item" [jobDetails]="jobDetails"
                                           [parent]="this" [translations]="translations"></app-dispatchpanel-details>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}} shadow-border">
              <td class="button_row" colspan="15">
                <app-dispatchpanel-actions
                  [activeTab]="activeTab" [companyId]="companyId"
                  [company]="company"
                  [context]="context" [item]="item" [jobDetails]="jobDetails"
                  [parent]="this" [translations]="translations"></app-dispatchpanel-actions>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="completedFilteredData.length === 0">
          <tr>
            <td [colSpan]="completedColumns.length"
                class="table-placeholder">
              {{ 'no_jobs_in_overview'|translate|ucFirst }}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #completedPagingBar (change)="completedPage($event)" [pageSize]="completedPageSize"
                       [total]="completedFilteredTotal">
          <span hide-xs>{{ 'rows_p_page'|translate }}:</span>
          {{ completedPagingBar.range }} <span hide-xs>of {{ completedPagingBar.total }}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">close</mat-icon>
          {{ 'canceled_jobs'|translate }}  ({{ (canceledFilteredTotal ? canceledFilteredTotal : '0') }})
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="canceledSort($event)"
                *ngFor="let c of canceledColumns"
                [active]="(canceledSortBy == c.name)"
                [name]="c.name"
                [numeric]="(!!c.numeric)"
                [sortOrder]="(canceledSortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="(!!c.sortable)"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}} {{c.maxWidth}}"
                td-data-table-column>
              {{ c.label|translate|ucFirst }}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="canceledFilteredData.length > 0">
          <ng-container *ngFor="let item of canceledFilteredData;">
            <tr class="pointer {{item.colorClass}}" td-data-table-row>
              <td (click)="(c.name !== 'actions' &&
                c.name !== 'check' &&
                ( c.name !== 'status' ||
                  (!item.LastOffer || (item.LastOffer && item.LastOffer.type !== 'sharedToGroup' && item.LastOffer.offerType !== 'failed'))
                ) ? toggleDetails(item.id) : '')" *ngFor="let c of canceledColumns" [numeric]="c.numeric"
                  class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td"
                  td-data-table-cell>
                    <span [ngSwitch]="c.name">
                      <span *ngSwitchCase="'check'">
                        <mat-checkbox
                          (ngModelChange)="toggleMultiAction()"
                          [(ngModel)]="multiSelectBox[item.id]"
                          [value]="item.id">
                        </mat-checkbox>
                      </span>

                      <span *ngSwitchCase="'reference'" matTooltip="{{item.tooltip}}">
                        <div class="color-coting">
                          <div *ngIf="item.priceMethod==='hourlyFixed'"
                               class="order-type"></div>
                          <div *ngFor="let metaItem of item.metaColor" class="meta-field-{{metaItem}}"></div>
                        </div>
                        {{ item[c.name] }}
                      </span>

                      <span *ngSwitchCase="'requestedDate' || 'dispatchTimeToPickup'">
                        <span
                          *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span>
                      </span>

                      <span *ngSwitchCase="'departure'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item.departure}}">{{ item.departure }}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'destination'" class="wrap-line">
                        <span *ngIf="item[c.name]" title="{{item.destination}}">{{ item.destination }}</span>
                        <span *ngIf="!item[c.name]">-</span></span>
                      <span *ngSwitchCase="'status'">
                        <span [ngSwitch]="item.offerType">
                          <span (click)="openDispatchLinkDialog((item.LastOffer ? item.LastOffer : ''), item)"
                                *ngSwitchCase="'sharedToGroup'"
                                class="wrap-line dispatch-active">{{ item.statusTxt }}</span>
                          <span *ngSwitchCase="'company'" class="wrap-line dispatch-active">
                            <span class="dispatch-active-text">{{ item.statusTxt }}</span>
                            <div class="snippet" data-title=".dot-pulse">
                              <div class="stage">
                                <div class="dot-pulse"></div>
                              </div>
                            </div>
                          </span>
                          <span (click)="openDispatchLog(item.id);" *ngSwitchCase="'failed'"
                                class="wrap-line dispatch-active tc-red-600">
                            {{ item.statusTxt | ucFirst }}
                          </span>
                          <span *ngSwitchDefault class="wrap-line">{{ item.statusTxt }}</span>
                        </span>
                      </span>

                      <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name] }}</span><span
                        *ngIf="!item[c.name]">-</span></span>

                      <span *ngSwitchCase="'actions'" class="wrap-line">
                        <button [matMenuTriggerFor]="menu" mat-icon-button>
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button (click)="copyRide(item.id)"
                                mat-menu-item>
                            <mat-icon class="icon-right">file_copy</mat-icon>
                            <span>{{ 'copy_ride' | translate | ucFirst }}
                              {{ item['copy_ride'] }}</span>
                          </button>

                          <button (click)="copyRide(item.id, 'return')"
                                  mat-menu-item>
                            <mat-icon class="icon-right">compare_arrows</mat-icon>
                            <span>{{ 'return_ride' | translate | ucFirst }}
                              {{ item['return_ride'] }}</span>
                          </button>
                          <button (click)="returnJobToAssign(item.id)"
                                  *ngIf="['canceled', 'canceled_passenger', 'canceled_driver'].includes(item.status)"
                                  mat-menu-item>
                            <mat-icon class="icon-right">arrow_back_ios</mat-icon>
                            <span>{{ 'return_job_to_be_assigned' | translate | ucFirst }}</span>
                          </button>
                        </mat-menu>
                      </span>
                    </span>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}}">
              <td colspan="15">
                <app-dispatchpanel-details *ngIf="this.jobDetails[item.id] === 'expanded'"
                                           [activeTab]="activeTab" [companyId]="companyId"
                                           [company]="company"
                                           [context]="context" [item]="item" [jobDetails]="jobDetails"
                                           [parent]="this" [translations]="translations"></app-dispatchpanel-details>
              </td>
            </tr>
            <tr class="{{jobDetails[item.id]}} shadow-border">
              <td class="button_row" colspan="15">
                <app-dispatchpanel-actions
                  [activeTab]="activeTab" [companyId]="companyId"
                  [company]="company"
                  [context]="context" [item]="item" [jobDetails]="jobDetails"
                  [parent]="this" [translations]="translations"></app-dispatchpanel-actions>
              </td>
            </tr>
          </ng-container>
          </tbody>
          <tbody *ngIf="canceledFilteredData.length === 0">
          <tr>
            <td [colSpan]="canceledColumns.length"
                class="table-placeholder">
              {{ 'no_jobs_in_overview'|translate|ucFirst }}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #canceledPagingBar (change)="canceledPage($event)" [pageSize]="canceledPageSize"
                       [total]="canceledFilteredTotal">
          <span hide-xs>{{ 'rows_p_page'|translate }}:</span>
          {{ canceledPagingBar.range }} <span hide-xs>of {{ canceledPagingBar.total }}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
