import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

/**
 * Dialog to select image from an image gallery
 */
@Component({
  selector: 'app-product-select-image-dialog',
  templateUrl: './product-select-image-dialog.component.html',
  styleUrls: ['./product-select-image-dialog.component.scss']
})
export class ProductSelectImageDialogComponent implements OnInit {
  defaultImages = [
    'https://de122c61b7b8d58cef8d-e55b398e1e03bf373283e24167356a82.ssl.cf3.rackcdn.com/products/5f6884951fe984a6add067ec/5f78ae847d55011940fa5979/product.png',
    'https://de122c61b7b8d58cef8d-e55b398e1e03bf373283e24167356a82.ssl.cf3.rackcdn.com/products/64075b1f1cdcfa2ee52de492/64075b1fc368fcbde5918149/product.png',
    'https://de122c61b7b8d58cef8d-e55b398e1e03bf373283e24167356a82.ssl.cf3.rackcdn.com/products/5f6884951fe984a6add067ec/5f78ae847d5501701efa5977/product.png',
    'https://de122c61b7b8d58cef8d-e55b398e1e03bf373283e24167356a82.ssl.cf3.rackcdn.com/products/5f6884951fe984a6add067ec/5f78ae847d5501ed3cfa5978/product.png',
  ];

  constructor(
    public _dialogRef: MatDialogRef<ProductSelectImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.defaultImages);
  }

  close() {

  }

  selectImage(image: any) {
    this._dialogRef.close(image);
  }
}
