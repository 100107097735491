import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {UpsertComponent} from '../../../global/upsert/upsert.component';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {NavigationService} from '../../../../services/navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../../../services/company.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {CountryService} from '../../../../services/country.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {WebbookerService} from '../../../../services/webbooker.service';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {CurrencyService} from '../../../../services/currency.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {environment} from '../../../../../environments/environment';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AvailabilityOverrideComponent} from './parts/availability-overwrite/availability-override.component';
import {Company} from 'app/models/company';
import {PricingComponent} from '../pricing/pricing.component';
import {PricingRuleService} from '../../../../services/tps/pricing-rule.service';
import {GlobalFunctions} from '../../../../functions/functions';

@Component({
  selector: 'app-availability-settings',
  templateUrl: './availability-settings.component.html',
  styleUrls: ['./availability-settings.component.scss'],
  providers: [PricingRuleService]
})
export class AvailabilitySettingsComponent extends UpsertComponent implements OnInit, OnDestroy {
  company: Company;
  times = [];
  priceError: string;
  removeEventListener: any;
  times2 = [];
  days = [
    'mon', 'thu', 'wed', 'thr', 'fri', 'sat', 'sun'
  ];
  @ViewChild('availabilityOverride') availabilityOverrideComponent!: AvailabilityOverrideComponent;

  constructor(
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _companyService: CompanyService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _titleService: Title,
    private _currencyService: CurrencyService,
    private _webbookerService: WebbookerService,
    private _vault: CoolLocalStorage,
    private _daAppInstallService: DaAppInstallService,
    private _pricingRuleService: PricingRuleService,
    private renderer: Renderer2,
    private elRef: ElementRef) {
    super(_loadingService, _dialogService, _navigationService, _router, _snackBar, _companyService, _translateService, _route);
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    _translateService.get(['availability']).subscribe((translations: any) => {
      this._titleService.setTitle(ucFirst(translations['availability'] + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(translations['availability']);
    });

    const self = this;
    const {company} = this._route.parent.snapshot.data;
    this.id = company.id;
    this.company = company;

    this.currentAction = 'save';
    this.action = 'save';

    this.loadData();

    this._pricingRuleService.getAll({
      where: {
        companyId: this.companyId,
      },
      include: ['ruleLinks', 'prices', {
        'relation': 'childRules',
        'scope': {
          'include': ['ruleLinks', 'prices']
        }
      }]
    }).subscribe((pricingRules) => {
      GlobalFunctions.checkForErrors(this._daAppInstallService, this._vault, this._webbookerService, this.company, pricingRules).then((errors) =>  {
        this.priceError = errors;
      })
    })
  }

  loadData(): void {
    const self = this;
    this._companyService.get(this.id).subscribe((company) => {
      this.data = company;
      // tslint:disable-next-line:max-line-length
      if (!this.data.settings.availability || !this.data.settings.availability.availableDays || (!this.data.settings.availability.availableDays.mon && !this.data.settings.availability.availableDays.day_mon)) {
        if (!this.data.settings.availability) {
          this.data.settings.availability = {};
        }
        this.data.settings.availability.availableDays = {
          'mon': [{
            'active': true,
            'start': '09:00',
            'end': '17:00'
          }],
          'thu': [{
            'active': true,
            'start': '09:00',
            'end': '17:00'
          }],
          'wed': [{
            'active': true,
            'start': '09:00',
            'end': '17:00'
          }],
          'thr': [{
            'active': true,
            'start': '09:00',
            'end': '17:00'
          }],
          'fri': [{
            'active': true,
            'start': '09:00',
            'end': '17:00'
          }],
          'sat': [{
            'active': false,
            'start': '09:00',
            'end': '17:00'
          }],
          'sun': [{
            'active': false,
            'start': '09:00',
            'end': '17:00'
          }],
        };
      } else if (this.data.settings.availability.availableDays && this.data.settings.availability.availableDays['day_mon']) {
        const newDays = {};
        Object.keys(this.data.settings.availability.availableDays).forEach((v) => {
          const split = v.split('_');
          const newDay = {
            active: this.data.settings.availability.availableDays[v],
            start: this.data.settings.availability.availabilityStart,
            end: (this.data.settings.availability.availabilityEnd === '00:00' ? '24:00' : this.data.settings.availability.availabilityEnd)
          };
          newDays[split[1]] = [newDay];
        });
        this.data.settings.availability.availableDays = newDays;
      } else if (this.data.settings.availability.availableDays && (this.data.settings.availability.availableDays['mon'] && !this.data.settings.availability.availableDays['mon'][0])) {
        const newDays = {};
        Object.keys(this.data.settings.availability.availableDays).forEach((v) => {
          const newDay = {
            active: this.data.settings.availability.availableDays[v].active,
            start: this.data.settings.availability.availableDays[v].start,
            end: (this.data.settings.availability.availableDays[v].end === '00:00' ? '24:00' : this.data.settings.availability.availableDays[v].end)
          };
          newDays[v] = [newDay];
        });
        this.data.settings.availability.availableDays = newDays;
      }
      const formSetup: any = {};

      this.days.forEach((day) => {
        formSetup[day] = this._formBuilder.array([]);
        this.data.settings.availability.availableDays[day].forEach((intervals) => {
          formSetup[day].push(this._formBuilder.group({
            'active': [intervals['active'], []],
            'start': [intervals['start'], []],
            'end': [(intervals['end'] === '00:00' ? '24:00' : intervals['end']), []]
          }));
        });
      });
      this.form = this._formBuilder.group({
        'availableDays': this._formBuilder.group(formSetup),
      }, {validators: []});
    })
  }

  ngAfterViewInit() {
    const self = this;
    setTimeout(function () {
      self.removeEventListener = self.renderer.listen(self.elRef.nativeElement, 'click', (event) => {
        if (event && event.target && event.target && event.target.attributes && event.target.attributes.link) {
          if (event.target instanceof HTMLAnchorElement) {
            // Prevent opening anchors the default way
            event.preventDefault();
            // Your custom anchor click event handler
            self.openLink(event);
          }
        }
      });
    }, 1000);
  }

  openLink(event) {
    this._router.navigate([
      `/groups/${this.company.id}/${event.target.attributes.link.nodeValue}`
    ]);
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    if (this.removeEventListener) {
      this.removeEventListener();
    }
  }

  onTabChange(event: MatTabChangeEvent) {
    const self = this;
    if (event.index === 1) {
      this._navigationService.setActionFn(function () {
        self.availabilityOverrideComponent.startUpsert();
      });
    } else {
      this._navigationService.setActionFn('');
    }
  }

  saveDataMapping(saveData): any {
    this.form.updateValueAndValidity();
    saveData.settings.availability = this.form.getRawValue();
    return saveData;
  }

  addInterval(day) {
    const lastElement = this.form.controls['availableDays']['controls'][day].controls.length - 1;
    let start = this.form.controls['availableDays']['controls'][day].controls[lastElement].controls.end.value;
    if (start === '24:00') {
      start = '23:00';
    }
    this.form.controls['availableDays']['controls'][day].controls.push(this._formBuilder.group({
      'active': [true, []],
      'start': [start, [Validators.required]],
      'end': ['24:00', [Validators.required]]
    }));
    this.form.controls['availableDays'].updateValueAndValidity();
  }

  removeInterval(day, element) {
    this.form.controls['availableDays']['controls'][day].controls.splice(element, 1);
    this.form.controls['availableDays'].updateValueAndValidity();
  }

  getTimes(day, index): string[] {
    let startValue = 0
    let blockHalf = false;
    if (index > 0) {
      const currentValue = this.form.controls['availableDays']['controls'][day].controls[(index - 1)].controls['end'].value;
      const timeSplit = currentValue.split(':');
      // tslint:disable-next-line:radix
      startValue = parseInt(timeSplit[0]);
      if (timeSplit[1] === '30') {
        blockHalf = true;
      }
    }
    const times = [];
    for (let x = startValue; x < 24; x++) {
      if (!blockHalf) {
        times.push(`${x < 10 ? '0' + x : x}:00`);
      }
      times.push(`${x < 10 ? '0' + x : x}:30`);
    }
    return times;
  }

  getTimes2(day, index): string[] {
    let startValue = 0
    let endValue = 24
    let blockHalf = false;
    let blockHalfEnd = false;
    if (index > 0 && this.form.controls['availableDays']['controls'][day].controls[(index + 1)]) {
      const currentValue = this.form.controls['availableDays']['controls'][day].controls[(index + 1)].controls['start'].value;
      const timeSplit = currentValue.split(':');
      // tslint:disable-next-line:radix
      endValue = parseInt(timeSplit[0]);
      if (timeSplit[1] === '30') {
        blockHalfEnd = true;
      }
    }

    if (index > 0 && this.form.controls['availableDays']['controls'][day].controls[index]) {
      const currentValue = this.form.controls['availableDays']['controls'][day].controls[index].controls['start'].value;
      const timeSplit = currentValue.split(':');
      // tslint:disable-next-line:radix
      startValue = parseInt(timeSplit[0]);
      if (timeSplit[1] === '30') {
        blockHalf = true;
      }
    }

    const times = [];
    for (let x = startValue; x <= endValue; x++) {
      if (x > 0 && !blockHalf) {
        times.push(`${x < 10 ? '0' + x : x}:00`);
      }
      if (blockHalfEnd || x <= (endValue - 1)) {
        times.push(`${x < 10 ? '0' + x : x}:30`);
      }
    }
    return times;
  }

  clearSubRules(day) {
    if (this.form.controls['availableDays']['controls'][day] && this.form.controls['availableDays']['controls'][day].controls[0] && this.form.controls['availableDays']['controls'][day].controls[0].controls['active'].value) {
      const controls = JSON.parse(JSON.stringify(this.form.controls['availableDays']['controls'][day].controls.length));
      for (let i = controls; i >= 0; i--) {
        this.removeInterval(day, i);
      }
      this.form.controls['availableDays']['controls'][day].controls.push(this._formBuilder.group({
        'active': [false, []],
        'start': ['09:00', []],
        'end': ['24:00', []]
      }));
    }
  }
}
