<div class="dispatch-pane">
  <mat-card-subtitle
    class="push-bottom push-top">{{'dispatch_job_start_automatic_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <div class="table">
    <div (click)="(!this.ride.LastOffer ||
         ['success','expired','failed','canceled'].includes(this.ride.LastOffer.status) ?
         setAutoDispatchSelection('share') : null)" class="{{(this.ride.LastOffer && this.ride.LastOffer.type !== 'sharedToGroup' &&
         !['success','expired','failed','canceled'].includes(this.ride.LastOffer.status) ? 'disabled' : '')}}"
         flex="100"
         layout="row">

      <div class="table dispatch_option {{(this.modus === 'share' ? 'active' : '')}}">
        <div flex="100" layout-gt-md="row" layout-md="row" style="width:100%">
          <div class="mat-icon" flex="5" layout="column">
            <mat-icon class="option-icon send-icon">send</mat-icon>
          </div>
          <div class=" push-left push-right " flex="95" layout-gt-md="column"
               layout-md="column">
            {{'automatic_dispatch_start_share_description'|translate|ucFirst}}
          </div>
        </div>

        <div
          *ngIf="this.ride.LastOffer && !['success','expired','failed','canceled'].includes(this.ride.LastOffer.status)">
          <app-share-offer (stopDispatch)="_stopDispatch()" *ngIf="this.ride.LastOffer['type'] === 'sharedToGroup'"
                           [deepLinkUrl]="this.ride.LastOffer['deepLinkUrl']"
                           [ride]="this.ride"
                           flex="100"></app-share-offer>
        </div>

        <div flex-gt-md="50" flex-lt-md="100" layout-gt-md="row" layout-md="row">
          <button (click)="startShare()" *ngIf="this.ride.id && this.modus === 'share' &&
          (!this.ride.LastOffer || ['success','expired','failed','canceled'].includes(this.ride.LastOffer.status))" class="push-top" color="primary"
                  flex mat-raised-button>
            {{'automatic_dispatch_start_share'|translate|ucFirst}}</button>
        </div>
      </div>
    </div>

    <div (click)="(!this.ride.LastOffer ||
         ['success','expired','failed','canceled'].includes(this.ride.LastOffer.status) ?
         setAutoDispatchSelection('automatic') : null)" class="{{(this.ride.LastOffer && this.ride.LastOffer.type !== 'company' &&
         !['success','expired','failed','canceled'].includes(this.ride.LastOffer.status)  ? 'disabled' : '')}}"
         flex="100"
         layout="row">

      <div class="table dispatch_option {{(this.modus === 'automatic' ? 'active' : '')}}">
        <div flex="100" layout-gt-md="row" layout-md="row">
          <div class="mat-icon" flex="5" layout="column">
            <mat-icon class="option-icon send-icon">send</mat-icon>
          </div>
          <div class=" push-left push-right" flex="95" layout-gt-md="column"
               layout-md="column">
            {{'automatic_dispatch_start_dispatch_description'|translate|ucFirst}}
          </div>
        </div>
        <div
          *ngIf="this.ride.LastOffer && !['success','expired','failed','canceled'].includes(this.ride.LastOffer.status)">
          <app-active-automatic-dispatch (stopDispatch)="_stopDispatch()" *ngIf="this.ride.LastOffer['type'] === 'company'"
                                         [ride]="this.ride"
                                         flex="100"></app-active-automatic-dispatch>
        </div>

        <div class="dispatch-option-button" flex="100" layout="row">
          <div class="dispatch-option-button-pane" flex>
            <button (click)="(!this.ride.LastOffer ||
           ['success','expired','failed','canceled'].includes(this.ride.LastOffer.status) ?
           startAutomaticDispatch() : null)" *ngIf="this.ride.id && this.modus ===
            'automatic' &&
            (!this.ride.LastOffer || ['success','expired','failed','canceled'].includes(this.ride.LastOffer.status))" class="push-right push-top" color="primary" flex
                    flex-md="100" mat-raised-button>
              {{'automatic_dispatch_start_dispatch'|translate|ucFirst}}</button>
          </div>
          <div
            *ngIf="myCompanies.length > 0 && (this.ride.LastOffer &&
            ['success','expired','failed','canceled'].includes(this.ride.LastOffer.status) || !this.ride.LastOffer)">
            <mat-select (selectionChange)="selectGroup($event)"
                        *ngIf="this.modus === 'automatic'" [(value)]="selected"
                        [style.width.px]="300"
                        class="selectCompany row-select push-top"
                        id="activeCompany" required>
              <mat-select-trigger>
                <mat-icon class="push-right">{{ getSelectedIcon() }}</mat-icon>
                <span class="select-text">{{ getCompanyName() | translate | ucFirst}}</span>
              </mat-select-trigger>
              <mat-option *ngFor="let company of myCompanies" [value]="(company ? company.id : null )">
                <mat-icon>business</mat-icon>
                <span>{{ company.name | ucFirst }}</span>
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
