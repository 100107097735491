import {Component, Input, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {TranslateService} from '@ngx-translate/core';
import {DaAppInstall} from '../../../../../../../../models/da-app-install';
import {DaAppInstallService} from '../../../../../../../../services/da-app-install.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ucFirst} from '../../../../../../../../pipes/uc-first.pipe';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Company} from '../../../../../../../../models/company';
import {ValidateAllFormFields} from '../../../../../../../../functions/validateAllFormFields';

@Component({
  selector: 'app-settings-tab',
  templateUrl: './settings-tab.component.html',
  styleUrls: ['./settings-tab.component.scss'],
  animations: [
    trigger('advancedSlide', [
      state('collapsed', style({
        height: 'auto',
        display: 'block'
      })),
      state('expanded', style({
        height: '0px',
        display: 'none'
      })),
      transition('void => *', animate(0)),
      transition('collapsed => expanded', animate(1500)),
      transition('expanded => collapsed', animate(1500)),
    ])
  ]
})
export class SettingsTabComponent extends ValidateAllFormFields implements OnInit {
  @Input() daAppInstall: DaAppInstall;
  @Input() appType: string;
  @Input() advancedSettings: string;
  form: UntypedFormGroup;
  routeSub: Subscription;
  settings: any = {};
  company: Company;
  id: string;
  companyId: string;
  action: string;
  debug = false;
  translations: string[] = [];
  savedText: string;
  formErrors: any = {
    name: '',
  };
  constructor(public snackBar: MatSnackBar,
              private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _translateService: TranslateService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _daAppInstallService: DaAppInstallService) {
    super();
    this.loadErrorTranslations(_translateService);
    const {company} = this._route.parent.snapshot.data;
    this.company = company;

    _loadingService.register('settings');
    this.routeSub = this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      this.routeSub = this._route.params.subscribe(params => {
        this.id = params['id'];
        this.action = params['action'] ? params['action'] : params['id'];
        _translateService.get(['settings_saved', 'ok']).subscribe((translations: any) => {
          this.translations = translations;
          this.savedText = translations['settings_saved'];
        });
      });
    });
  }

  ngOnInit(): void {
    this.settings = this.daAppInstall.settings;
    if (typeof this.settings !== 'undefined') {
      this.prepareSettings();
    }
  }

  prepareSettings(): void {
    this._loadingService.resolve('settings');

    if (!this.settings.booking) {
      this.settings.booking = {};
    }
    if (!this.settings.dispatching) {
      this.settings.dispatching = {};
    }

    this.form = this._formBuilder.group({
      minimumOrderTime: [(this.settings.booking.minimumOrderTime ? this.settings.booking.minimumOrderTime / 60 : 30), []],
      dispatchingMode: [(this.settings.dispatching.mode ? this.settings.dispatching.mode : 'manual'), []],
      dispatchMethodThreshold: [(this.settings.dispatching.dispatchMethodThreshold ? this.settings.dispatching.dispatchMethodThreshold / 60 : 60), []],
      dispatchTimeToPickup: [(this.settings.dispatching.dispatchTimeToPickup ? this.settings.dispatching.dispatchTimeToPickup / 60 : 60), []],
      confirmAsapBookingsBeforeAssigned: [(this.settings.confirmAsapBookingsBeforeAssigned ? this.settings.confirmAsapBookingsBeforeAssigned : false), []],
      blockConfirmAsapBookingsBeforeAssigned: [(this.settings.blockConfirmAsapBookingsBeforeAssigned ? this.settings.blockConfirmAsapBookingsBeforeAssigned : false), []],
      blockPassengerCancelation: [(this.settings.blockPassengerCancelation ? this.settings.blockPassengerCancelation : false), []],
      instructionsUrl: [(this.settings && this.settings.mailInstructions && this.settings.mailInstructions.instructionsUrl ? this.settings.mailInstructions.instructionsUrl : false), []],
    }, {validators: []});

  }

  minutesFieldChanged(event: any): void {
    const group = event.target.name.split('.')[0];
    const id = event.target.id;
    const value = event.target.value;

    this.settings[group][id] = (value * 60);
  }

  changeSelection(event: any): void {
    const group = event.source._id.split('.')[0];
    const id = event.source._id.split('.')[1];
    this.settings[group][id] = event.value;
  }

  save(): void {
    const self = this;

    const data = this.form.value;
    this.settings.booking.minimumOrderTime = data.minimumOrderTime * 60;
    this.settings.dispatching.mode = data.dispatchingMode;
    this.settings.dispatching.dispatchMethodThreshold = data.dispatchMethodThreshold * 60;
    this.settings.dispatching.dispatchTimeToPickup = data.dispatchTimeToPickup * 60;
    this.settings.confirmAsapBookingsBeforeAssigned = data.confirmAsapBookingsBeforeAssigned;
    this.settings.blockConfirmAsapBookingsBeforeAssigned = data.blockConfirmAsapBookingsBeforeAssigned;
    this.settings.blockPassengerCancelation = data.blockPassengerCancelation;
    if (!this.settings.mailInstructions && data.instructionsUrl) {
      this.settings.mailInstructions = {};
    }
    if(data.instructionsUrl) {
      this.settings.mailInstructions.instructionsUrl = data.instructionsUrl;
    }

    this._daAppInstallService.update(this.daAppInstall.id, {settings: this.settings}, 'company').subscribe(() => {
      self.snackBar.open(ucFirst(self.translations['settings_saved']), self.translations['ok'], {
        duration: 3000
      });
    }, (error) => {
      console.log(`[SettingsTabComponent.save]: error`, error);
    })
  }

  toggleAdvanced() {
    this.advancedSettings = (this.advancedSettings === 'collapsed' ? 'expanded' : 'collapsed');
  }
}
