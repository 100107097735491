import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ucFirst} from '../../../../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {TranslateService} from '@ngx-translate/core';
import {RideService} from '../../../../../../../services/ride.service';

@Component({
  selector: 'app-dispatchpanel-cancel-job-dialog',
  templateUrl: './dispatchpanel-cancel-job-dialog.component.html',
  styleUrls: ['./dispatchpanel-cancel-job-dialog.component.scss']
})
export class DispatchpanelCancelJobDialogComponent implements OnInit {
  cancelationReasons = [
    'canceled_by_passenger',
    'passenger_did_not_show',
    'canceled_by_driver',
    'invalid_booking',
    'no_availability',
    'outside_workarea',
    'other',
  ];
  note = '';
  cancellationReason = 'canceled_by_passenger';
  parent;
  rideId = '';

  constructor(
    private snackBar: MatSnackBar,
    private _loadingService: TdLoadingService,
    private _translateService: TranslateService,
    private _rideService: RideService,
    public dialogRef: MatDialogRef<DispatchpanelCancelJobDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.parent = data.parent;
    this.rideId = data.rideId;
  }

  ngOnInit(): void {
  }

  cancelJob() {
    const self = this;
    const cancellationUpdate = {
      'status': 'canceled',
      'cancellation': {
        'reason': self.cancellationReason,
        'note': self.note
      }
    }

    self._rideService.cancelJob(self.rideId, cancellationUpdate, 'company').subscribe(() => {
      self._loadingService.resolve(self.parent.loaderName);
      self.snackBar.open(ucFirst(self._translateService.instant('job_canceled')), self._translateService.instant('ok'), {
        duration: 3000
      });
      // self._loadingService.register('rides.list');
      self.dialogRef.close()
      self.parent.loadData();
    });
  }
}
