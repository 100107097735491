<ng-container>
  <div class="advanced_settings_title">
    {{ 'advanced_settings'|translate|ucFirst }}
  </div>

  <div *ngIf="this.form && advancedSettings === 'expanded'" [formGroup]="form" class="advanced_settings">
    <div class="tab-content">
      <div class="label_title pull-top" flex="100" layout="row">{{ 'communication'|translate|ucFirst }}</div>
      <div flex="100" layout="row">
        <mat-checkbox
          formControlName="blockConfirmAsapBookingsBeforeAssigned">
          {{ 'webbooker_settings_confirm_asap_bookings_before_assign'|translate|ucFirst }}
        </mat-checkbox>
      </div>
      <div flex="100" layout="row">
        <mat-checkbox
          formControlName="blockPassengerCancelation">
          {{ 'disable_option_to_cancel_by_passenger'|translate|ucFirst }}
        </mat-checkbox>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'dispatch_settings'|translate|ucFirst }}</div>

      <div flex="100" layout="row">
        <mat-form-field flex="50" class="left_form" *ngIf="daAppInstall.DaApp.type !== 'dispatch'">
          <p [innerHtml]="'settings_default_dispatch_method'|translate|ucFirst" class="field-description"></p>
          <mat-select (selectionChange)="changeSelection($event)"
                      class="row-select"
                      flex="33"
                      formControlName="dispatchingMode" id="dispatching.mode" required>
            <mat-option value="manual">{{ 'manual'|translate|ucFirst }}</mat-option>
            <mat-option value="automatic">{{ 'automatic'|translate|ucFirst }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div flex="100" layout="row" *ngIf="appType !== 'webbooker'">
        <div flex="50" layout="column">
          <mat-form-field class="full-width">
            <label [innerHtml]="'minimum_order_time'|translate|ucFirst" for="minimumOrderTime"></label>
            <p [innerHtml]="'minimum_order_time_description'|translate|ucFirst" class="field-description"></p>
            <input (change)="minutesFieldChanged($event)"
                   formControlName="minimumOrderTime"
                   id="minimumOrderTime"
                   matInput
                   name="booking.minimumOrderTime"
                   type="number">
          </mat-form-field>
        </div>
      </div>

      <div flex="100" layout="row">
        <mat-form-field flex="50" class="left_form">
          <label [innerHtml]="'dispatch_method_threshold'|translate|ucFirst" for="dispatchMethodThreshold"></label>
          <p [innerHtml]="'dispatch_method_threshold_description'|translate|ucFirst" class="field-description"></p>
          <input (change)="minutesFieldChanged($event)"
                 formControlName="dispatchMethodThreshold"
                 id="dispatchMethodThreshold"
                 matInput
                 type="number">
        </mat-form-field>

        <mat-form-field flex="50">
          <label [innerHtml]="'dispatch_time_to_pickup'|translate|ucFirst" for="dispatchTimeToPickup"></label>
          <p [innerHtml]="'dispatch_time_to_pickup_description'|translate|ucFirst" class="field-description"></p>
          <input (change)="minutesFieldChanged($event)"
                 formControlName="dispatchTimeToPickup"
                 id="dispatchTimeToPickup"
                 matInput
                 type="number">
        </mat-form-field>
      </div>
    </div>

    <div class="tab-content">
      <mat-form-field flex="100" style="width:90%">
        <label [innerHtml]="'instructions_url'|translate|ucFirst" for="dispatchTimeToPickup"></label>
        <input formControlName="instructionsUrl" matInput type="text">
      </mat-form-field>
      <a class="yda-link-blue more_info" flex href="{{form.controls['instructionsUrl'].value}}"
         target="_blank">{{ 'open'|translate|ucFirst }}</a>
    </div>

    <mat-divider></mat-divider>
    <div class="tab-content">
      <button (click)="save()" color="accent" mat-raised-button>{{ 'save'|translate|ucFirst }}</button>
    </div>
    <div *ngIf="debug" class="tab-content">
      <h4 class="push-top-none">Settings:</h4>
      <pre>{{ settings|json }}</pre>
    </div>
  </div>
</ng-container>
