<div [formGroup]="form" flex="100" class="condition-pane" *ngIf="this.form.controls['requirements'].controls[index]">
  <div formArrayName="requirements">
    <div [formGroupName]="index">
      <mat-card-title>{{'message_center_conditions' | translate | ucFirst}}</mat-card-title>

      <div class="row">
        <span>{{'when' | translate | ucFirst}}</span>
        <div class="inline-flex-pane first-match-condition">
          <mat-form-field [style.width.px]="325">
            <mat-select
              class="row-select"
              flex="100"
              formControlName="firstMatch" required>
              <mat-option *ngFor="let matchValue of matchValues index as i" [value]="matchValue">
                {{matchValue + '_condition'|translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <span *ngIf="hasAnd">{{'and'|translate}}</span>
      </div>

      <div class="row" *ngIf="hasAnd || this.form.controls['requirements'].controls[index].controls['secondMatch'].value">
        <div class="inline-flex-pane">
          <mat-form-field>
            <mat-label>{{'select_job_attribute'|translate|ucFirst}}</mat-label>
            <mat-select
              (selectionChange)="secondMatchChanged()"
              class="row-select"
              flex="100"
              formControlName="secondMatch" required>
              <mat-option *ngFor="let matchValue of matchValues2 index as i" [value]="matchValue.field">
                {{'job_attribute_'+matchValue.field.toLowerCase()|translate|ucFirst}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <span class="inline-text" *ngIf="this.form.controls['requirements'].controls[index].controls['secondMatch'].value">{{ 'contains' | translate }}</span>

        <div class="inline-flex-pane" *ngIf="matchValues2Input.includes(this.form.controls['requirements'].controls[index].controls['secondMatch'].value)">
          <mat-form-field class="push-right time_value" flex>
            <input formControlName="secondMatchValue" #secondMatchValue matInput placeholder="{{'value'|translate|ucFirst}}" type="text"
                   value="" >
          </mat-form-field>
        </div>
        <div class="inline-flex-pane" *ngIf="this.form.controls['requirements'].controls[index].controls['secondMatch'].value==='debtorCode'">
          <mat-form-field>
            <mat-select
              class="row-select"
              flex="100"
              formControlName="secondMatchValue" required>
              <mat-option *ngFor="let debtor of debtors index as i" [value]="debtor.companyName">
                {{debtor.companyName|translate|ucFirst}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="inline-flex-pane" *ngIf="this.form.controls['requirements'].controls[index].controls['secondMatch'].value==='paymentMethod'">
          <mat-form-field>
            <mat-select
              class="row-select"
              flex="100"
              formControlName="secondMatchValue" required>
              <mat-option *ngFor="let matchValue of paymentMethods index as i" [value]="matchValue">
                {{'payment_origin_'+matchValue.toLowerCase()|translate|ucFirst}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="inline-flex-pane" *ngIf="this.form.controls['requirements'].controls[index].controls['secondMatch'].value==='cancelationReason'">
          <mat-form-field>
            <mat-select
              class="row-select"
              flex="100"
              formControlName="secondMatchValue" required>
              <mat-option *ngFor="let matchValue of cancelationReasons index as i" [value]="matchValue">
                {{'cancellation_reason_'+matchValue.toLowerCase()|translate|ucFirst}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="inline-flex-pane" *ngIf="this.form.controls['requirements'].controls[index].controls['secondMatch'].value==='requestedDate'">
          <mat-form-field [ngStyle]="{'width': '25%'}" class="date-input push-right">
            <input
              [min]="minDate"
              formControlName="startDate"
              matInput
              placeholder="{{ 'start_date' | translate | ucFirst }}"
              type="datetime-local">
          </mat-form-field>

          <mat-form-field [ngStyle]="{'width': '25%'}" class="date-input push-right">
            <input
              [min]="maxDate"
              formControlName="endDate"
              matInput
              placeholder="{{ 'end_date' | translate | ucFirst }}"
              type="datetime-local">
          </mat-form-field>
        </div>

        <button (click)="removeCondition(index)" mat-icon-button matTooltip="{{'remove_condition'|translate|ucFirst}}">
            <mat-icon>delete</mat-icon>
          </button>
      </div>

      <div class="row">
        <div class="inline-flex-pane">
          <mat-form-field [style.width.px]="325">
            <mat-select
              class="row-select"
              flex="100"
              (selectionChange)="timeChanged()"
              formControlName="timeMatch" required>
              <mat-option *ngFor="let matchValue of getMatchValues3() index as i" [value]="matchValue.field">
                {{matchValue.field|translate|ucFirst}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="inline-flex-pane" *ngIf="matchValues3Minutes.includes(this.form.controls['requirements'].controls[index].controls['timeMatch'].value)">
          <mat-form-field class="push-right time_value" flex>
            <input formControlName="timeValue" helperText="required" matInput placeholder="{{'minutes'|translate|ucFirst}}" type="text"
                   value="" >
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<mat-card-actions>
  <button (click)="close()" mat-flat-button *ngIf="false">
    {{ 'close' | translate | ucFirst }}
  </button>
  <button (click)="save()" mat-raised-button color="accent" class="td-menu-button">
    {{ 'select' | translate | ucFirst}}
  </button>
  <button (click)="addCondition(index)" mat-stroked-button class="and-button" *ngIf="!hasAnd && !this.form.controls['requirements'].controls[index].controls['secondMatch'].value">
    {{ 'add_extra_condition' | translate }}
  </button>
</mat-card-actions>
