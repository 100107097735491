import {Inject, Injectable, Renderer2, RendererFactory2} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../environments/environment';
import moment from 'moment/moment';
import {Observable} from 'rxjs/Observable';
import {ucFirst} from '../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

export class GlobalFunctions {
  instance: any;
  callback: string;
  tokenClient;
  private _renderer2: Renderer2;

  static createTimeFrameFromAvailability(availability) {

    if (!availability || !availability.availableDays){
      return;
    }

    const days = [
      'mon', 'thu', 'wed', 'thr', 'fri', 'sat', 'sun'
    ];
    /**
     * Create an array with timeframes from an availability object
     * The array contains an index for each hour of the week
     * the value is 0 if outside the availability.availableDays or 1 if within an timefroma of the
     * availability.availabileDays array
     */
    const timeframes = [];
    const availabileDays = availability.availableDays;
    /**
     * Loop that fills all hours of an week
     */
    if (availabileDays['day_mon'] || availabileDays['mon']) {
      return;
    }

    for (let i = 0; i < 7; i++) {
      for (let h = 0; h < 24; h++) {
        let availableValue = 0;
        /**
         * Get the time arrays from availabileDays for each day
         */
        availabileDays[days[i]].forEach((timeFrame) => {
          const startSplit = timeFrame.start.split(':');
          const endSplit = timeFrame.end.split(':');
          if (timeFrame.active && h >= startSplit[0] && h <= (endSplit[0] - 1)) {
            availableValue = 1;
          }
        });
        timeframes.push(availableValue);
      }
    }
    return timeframes;
  }

  static compareTimeFrames(a, b): boolean {
    /**
     * Check if timeframe b has all 1 values in a as well
     */
    if(a) {
      const missedFrame = a.map((value, index) => {
        return (value && value !== b[index] ? 1 : 0);
      });
      return missedFrame;
    } else {
      return false;
    }
  }

  static checkForErrors(_daAppInstallService, _vault, _webbookerService, company, pricingRules) {
    let priceError;
    company.settings.availabilityTimeFrame = GlobalFunctions.createTimeFrameFromAvailability(company.settings.availability);
    const hasNoDynamic = !(pricingRules.filter((r) => {
      return r.type === 'dynamic'
    })[0]);

    if (hasNoDynamic) {
      return Promise.resolve('webbooker_no_dynamic_in_account');
    }
    return GlobalFunctions.getWebBookers(_daAppInstallService, _vault, _webbookerService, company)
      .then((webbookers) => {
        let openingTimeError = true;
        pricingRules.every((r) => {
          if (r.taxiMeter) {
            openingTimeError = false;
          }

          if (r.type === 'dynamic') {
            /**
             * Specific times? Or period?
             */
            if (r.timeframes) {
              const completeDays = r.timeframes.filter((t) => {
                return (!t.isSpecificWeekDays && moment(t.startDate).isBefore() && moment(t.endDate).isAfter());
              });
              const isSpecific = r.timeframes.filter((t) => {
                return (t.isSpecificWeekDays && moment(t.startDate).isBefore() && moment(t.endDate).isAfter());
              });

              if (completeDays.length > 0) {
                openingTimeError = false;
              }

              if (isSpecific.length > 0) {
                company.settings.availabilityTimeFrame = GlobalFunctions.compareTimeFrames(company.settings.availabilityTimeFrame, Array.from(r.timeframes[0].weekSchedule, Number));
                if (company.settings.availabilityTimeFrame) {
                  openingTimeError = (company.settings.availabilityTimeFrame.filter((t) => t).length > 0);
                }
              }
            }
          }
          return true;
        });

        if (openingTimeError) {
          priceError = 'not_all_openingtimes_covert';
        }

        const hourlyForms = webbookers.filter((w) => {
          if (w.form) {
            return w.form.config.hourlyBookings;
          }
        });

        let hourlyError = false;
        if (hourlyForms.length > 0) {
          hourlyError = true;

          pricingRules.every((r) => {
            if (r.type === 'hourly') {
              hourlyError = false;
              return hourlyError;
            } else {
              return true;
            }
          });
        }
        if (hourlyError) {
          priceError = 'hourly_active_but_missing_pricing_rule';
        }
        return Promise.resolve(priceError)
      });
  }

  static getWebBookers(_daAppInstallService, _vault, _webbookerService, company): Promise<any> {
    let webbookers;
    return new Promise((resolve, reject) => {
      _daAppInstallService.getAll({
        where: {
          daAppId: environment.webbookerDaAppId,
          companyId: company.id,
        }
      }).subscribe((bookers) => {
        webbookers = bookers.filter((b) => {
          return (b.formId)
        });

        webbookers = webbookers.map((booker, i) => {
          if (booker.formId) {
            booker.JWTtoken = _vault.getItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`);
            if (!booker.JWTtoken) {
              _daAppInstallService.refreshToken(booker.formId)
                .then((token) => {
                  booker.JWTtoken = token;
                  _vault.setItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`, token);
                });
            }
            _webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
              .subscribe((formDetails) => {
                booker.form = formDetails;
                if (i === webbookers.length - 1) {
                  resolve(webbookers);
                }
              });
          }
          return booker;
        });
      });
    });
  }

  static checkWebBookerForError(_daAppInstallService, _tpsDaAppInstallService, daAppInstall, company): Promise<any> {
    return new Promise((resolve) => {
      _tpsDaAppInstallService.get(daAppInstall.id, {
        where: {companyId: company.companyId},
        include: [
          {
            relation: 'rules',
            scope: {
              where: {
                or: [
                  {parentRuleId: {exists: false}},
                  {parentRuleId: null}
                ]
              }
            }
          }
        ]
      }).subscribe((tpsDaAppInstall) => {
        const pricingRules = tpsDaAppInstall.rules;
        let priceError;

        if (!pricingRules || pricingRules.length === 0) {
          return resolve('webbooker_no_pricing_rules');
        }

        company.settings.availabilityTimeFrame = GlobalFunctions.createTimeFrameFromAvailability(company.settings.availability);


        const hasNoDynamic = !(pricingRules.filter((r) => {
          return r.type === 'dynamic'
        })[0]);
        if (hasNoDynamic) {
          if (!company.settings.serviceArea) {
            return resolve('webbooker_no_dynamic_and_no_servicearea');
          } else {
            return resolve('webbooker_no_dynamic_rule');
          }
        }

        let openingTimeError = true;
        pricingRules.every((r) => {
          if (r.taxiMeter) {
            openingTimeError = false;
          }

          if (r.type === 'dynamic') {
            /**
             * Specific times? Or period?
             */
            const completeDays = r.timeframes.filter((t) => {
              return (!t.isSpecificWeekDays && moment(t.startDate).isBefore() && moment(t.endDate).isAfter());
            });
            const isSpecific = r.timeframes.filter((t) => {
              return (t.isSpecificWeekDays && moment(t.startDate).isBefore() && moment(t.endDate).isAfter());
            });

            if (completeDays.length > 0) {
              openingTimeError = false;
            }
            if (isSpecific.length > 0) {
              company.settings.availabilityTimeFrame = GlobalFunctions.compareTimeFrames(company.settings.availabilityTimeFrame, Array.from(r.timeframes[0].weekSchedule, Number));
              if (company.settings.availabilityTimeFrame) {
                openingTimeError = (company.settings.availabilityTimeFrame.filter((t) => t).length > 0);
              }
            }
          }
          return true;
        });


        if (openingTimeError) {
          priceError = 'webbooker_not_all_openingtimes_covert';
        }
        const hourlyForms = daAppInstall.form.config.hourlyBookings;

        let hourlyError = false;
        if (hourlyForms.length > 0) {
          hourlyError = true;

          pricingRules.every((r) => {
            if (r.type === 'hourly') {
              hourlyError = false;
              return hourlyError;
            } else {
              return true;
            }
          });
        }
        if (hourlyError) {
          priceError = 'hourly_active_but_missing_pricing_rule';
        }

        return resolve(priceError)
      });
    });
  }

  /**
   * Guard checks whether component can be deactivated.
   */
  static canDeactivate(_dialogService: TdDialogService, _translationService: TranslateService, form: any): Observable<boolean> | boolean {
    if (form.pristine) {
      return true;
    }
    return _dialogService.openConfirm({
      message: _translationService.instant('location_confirm_leave'),
      disableClose: false,
      title: _translationService.instant('unsaved_changes'),
      acceptButton: ucFirst(_translationService.instant('leave')),
      cancelButton: ucFirst(_translationService.instant('stay')),
    }).afterClosed();
  }

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this._renderer2 = rendererFactory.createRenderer(null, null);
  }

  loadScript(url
               :
               string, instance
               :
               any = false, callback
               :
               string = ''
  ) {
    const self = this;
    this.instance = instance;
    this.callback = callback;
    let script = this._renderer2.createElement('script');
    script.src = url;
    let done = false;
    script.onload = script.onreadystatechange = function () {
      if (!done && (!this.readyState ||
        this.readyState === 'loaded' || this.readyState === 'complete')) {
        done = true;
        if (typeof (callback) !== 'undefined' && typeof (self[callback]) !== 'undefined') {
          self[callback]();
        }
        if (typeof (callback) !== 'undefined' && typeof (instance[callback]) !== 'undefined') {
          instance[callback]();
        }
        // Handle memory leak in IE
        script.onload = script.onreadystatechange = null;
      }
    };
    this._renderer2.appendChild(this._document.body, script);
  }

  initGoogleLogin(instance)
    :
    void {
    if (typeof (google) === 'undefined'
    ) {
      this.loadScript(`https://accounts.google.com/gsi/client`, instance, 'gLoginIsLoaded');
    } else {
      this.gLoginIsLoaded();
    }
  }

  initGoogleSignup(instance)
    :
    void {
    if (typeof (google) === 'undefined'
    ) {
      this.loadScript(`https://accounts.google.com/gsi/client`, instance, 'gisLoaded');
    } else {
      this.gisLoaded();
    }
  }

  gLoginIsLoaded()
    :
    void {
    // @ts-ignore
    this.tokenClient = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleCalendarClientId,
      scope: 'https://www.googleapis.com/auth/calendar' +
        ' https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      prompt: 'select_account',
      'ux_mode': 'popup',
      redirect_uri: 'https://api.dispatchapi.io',
      callback: '', // defined later
    });
  }

  gisLoaded()
    :
    void {
    // @ts-ignore
    this.tokenClient = google.accounts.oauth2.initCodeClient({
      client_id: environment.googleCalendarClientId,
      scope: 'https://www.googleapis.com/auth/calendar' +
        ' https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
      prompt: 'consent',
      'ux_mode': 'popup',
      redirect_uri: 'https://api.dispatchapi.io',
      callback: '', // defined later
    });
  }

  handleAuthClick()
    :
    void {
    this.tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw (resp);
      }

      if (typeof (this.instance['googleLogin']) !== 'undefined') {
        this.instance['googleLogin'](resp);
      }
    };
    this.tokenClient.requestCode({prompt: 'consent', response_type: 'offline', type: 'offline'});
  }

  loginCallback(resp)
    :
    void {
    console.log(resp);
  }
}
