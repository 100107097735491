export const environment = {
  location: 'staging',
  buildName: 'yda',
  portalName: 'YourDriverPortal',
  gtmId: 'G-44E58KT2LZ',
  companyBuild: false,
  companyId: '5a37959f468791219bc08c0f',
  googleLoginClientId: '56407099630-l9skotlsdf8cbm8h0tij53kuj5c95khj.apps.googleusercontent.com',
  googleCalendarClientId: '56407099630-64q8be4pcn5clgsb92kckikgn6qc9aq0.apps.googleusercontent.com',
  daAppId: '599ab313eea012253a008786',
  driverAppInstallationId: '59648a53cd96060d18f28c59',
  webbookerDaAppId: '5d9318e22c82763b30779e58',
  passengerDaAppId: '5911846519ccf4352bb46d65',
  production: false,
  showSupportButton: true,
  showCompanyInvoices: true,
  modules: {
    DriverModule: true,
    CompanyModule: true
  },
  vaultPrefix: 'yda.portal',
  apiBaseUrl: 'https://s-api.dispatchapi.io/api',
  apiAccessToken: '3XUC3FKOFIQZ0BowSFLHm9WZ2tjGATWV',
  orderFormApiUrl: 'https://s-order.dispatchapi.io',
  orderFormFrontEndUrl: 'https://s-order.yourdriverapp.com',
  tpsBaseUrl: 'https://s-tps.dispatchapi.io/api',
  pasBaseUrl: 'https://s-pas.dispatchapi.io/api',
  translationId: '591afd8a17f51db67fc57968',
  portalUrl: 'https://s-portal.yourdriverapp.com',
  googleApiKey: 'AIzaSyBrd7sl74-EU7tqtWYK_jyd0i0quamBbQU',
  webbookerPriceId: 'price_1J5BBMAAAqH6BJkpQYnjxT82',
  mapLineColor: '#03A9F4',
  windowTitleSuffix: ' - YourDriverPortal',
  colors: {
    primary: '#03A9F4',
    accent: '#455A64'
  },
  speedUnit: 'kmh',
  speedUnitMultiplier: 3.6,
  stripeKey: 'pk_test_51Hk3p7AAAqH6BJkpNpMpwWtSOW5HnpzPXNYImfzbuZilQtW7PAZ0bZcadDSPyul7CT9gEDrrEtt70JcMXcdPq9h3004NacQkCX',
  stripeCloudConnectId: 'price_1I74hrAAAqH6BJkpqIxHTcnz',
  stripeOauthClientId: 'ca_IL9aGmAWi3I7OHTZHCMQUVQ2pzDLckWZ',
  extendedDispatcherPriceId: 'price_1HoEWcAAAqH6BJkp9JD80XCA',
  products: {
    webbooker: {
      'priceId': 'price_1LJHJ2AAAqH6BJkpfwDYEKEj',
      'features': {
        'responsive_layout': 'yes',
        'widget': 'yes',
        'customer_email_confirmations': 'yes',
        'fare_calculations': 'yes',
        'stripe_integration': 'yes',
        'custom_products': 'yes',
        'customer_ratings': 'yes',
        'customer_coupons': 'yes',
        'customer_portal': 'no',
        'operator_notifications': 'yes',
        'manage_customers': 'no',
        'assign_bookings': 'no',
        'whatsapp_telegram_integrations': 'no',
        'gps_tracking_assigned': 'no',
        'dispatchpanel_create_jobs': 'no',
        'gps_tracking_fleet': 'no',
        'free_updates': 'yes',
        'sms_integrations': 'no',
        'api': 'no',
        'mobile_dispatch_app': 'no',
        'driver_app': 'no',
        'price_per_month': '€5,-',
        'billing_cycle': 'yearly',
        'setup_costs': '€0,-',
        'bookings_included': '600_year',
        'extra_bookings': '€0,10',
      }
    },
    webbookerPlus: {
      'priceId': 'price_1LKjI5AAAqH6BJkpnBIAHpTb',
      'upgradeId': 'UR16VCc0',
      'features': {
        'responsive_layout': 'yes',
        'widget': 'yes',
        'customer_email_confirmations': 'yes',
        'fare_calculations': 'yes',
        'stripe_integration': 'yes',
        'custom_products': 'yes',
        'customer_ratings': 'yes',
        'customer_coupons': 'yes',
        'customer_portal': 'yes',
        'operator_notifications': 'yes',
        'manage_customers': 'yes',
        'assign_bookings': 'yes',
        'whatsapp_telegram_integrations': 'yes',
        'gps_tracking_assigned': 'yes',
        'dispatchpanel_create_jobs': 'no',
        'gps_tracking_fleet': 'no',
        'free_updates': 'yes',
        'sms_integrations': 'yes',
        'api': 'yes',
        'mobile_dispatch_app': 'yes',
        'driver_app': 'yes',
        'price_per_month': '€15,-',
        'billing_cycle': 'yearly',
        'setup_costs': '€0,-',
        'bookings_included': '1800_year',
        'extra_bookings': '€0,10',
      }
    },
    fleetSolutions: {
      'priceId': 'price_1LJHJlAAAqH6BJkpzTP9kp7B',
      'features': {
        'responsive_layout': 'yes',
        'widget': 'yes',
        'customer_email_confirmations': 'yes',
        'fare_calculations': 'yes',
        'stripe_integration': 'yes',
        'custom_products': 'yes',
        'customer_ratings': 'yes',
        'customer_coupons': 'yes',
        'customer_portal': 'yes',
        'operator_notifications': 'yes',
        'manage_customers': 'yes',
        'assign_bookings': 'yes',
        'whatsapp_telegram_integrations': 'yes',
        'gps_tracking_assigned': 'yes',
        'dispatchpanel_create_jobs': 'yes',
        'gps_tracking_fleet': 'yes',
        'free_updates': 'yes',
        'sms_integrations': 'yes',
        'api': 'yes',
        'mobile_dispatch_app': 'yes',
        'driver_app': 'yes',
        'price_per_month': '€30,-',
        'billing_cycle': 'monthly',
        'setup_costs': '€0,-',
        'bookings_included': '300_month',
        'extra_bookings': '€0,10',
      }
    }
  },
  webBookerDefault: {
    luggage: {
      'type': 'select',
      'property': 'luggage',
      'meta': true,
      'quotation': true,
      'numeric': true,
      'label': 'luggage_field_label',
      'helpText': 'luggage_field_help_text_pretax',
      'placeholder': 'luggage_field_placeholder',
      'default': '',
      'values': [
        {
          'value': '',
          'label': 'luggage_field_please_select'
        },
        {
          'value': 0,
          'label': 'luggage_field_value_zero'
        },
        {
          'value': 1,
          'label': 'luggage_field_value_singular'
        },
        {
          'value': 2,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 3,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 4,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 5,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 6,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 7,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 8,
          'label': 'luggage_field_value_plural'
        }
      ],
      'required': false
    }
  },
  stripeTaxRates: [
    'txr_1I1uLvAAAqH6BJkpoV5cn5Sm'
  ],
  'paymentMethods': ['inTaxi', 'invoice', 'online', 'creditcardOnline'],
  languages: ['en', 'de', 'fr', 'es', 'it', 'nl', 'ua', 'ar']
};
