import {finalize} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {CanDeactivateGuard} from 'app/guards/can-deactivate-guard';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Discount} from '../../../../../../models/discount';
import {DiscountService} from '../../../../../../services/tps/discount.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../../../services/navigation.service';
import {PricingRuleService} from '../../../../../../services/tps/pricing-rule.service';
import * as tpsDaAppInstallService from '../../../../../../services/tps/da-app-install.service';
import {DaAppInstallService} from '../../../../../../services/da-app-install.service';
import {UtilityService} from '../../../../../../services/utility.service';
import {Company} from '../../../../../../models/company';

enum Action {
  add,
  details,
}

// Converts data stored in cents to euros or dollars
const fromCents = (value: number) => (value / 100).toFixed(2);
const toCents = (value: number) => value * 100;

@Component({
  selector: 'app-pricing-discounts-upsert',
  templateUrl: './pricing-discounts-upsert.component.html',
  styleUrls: ['./pricing-discounts-upsert.component.scss'],
  providers: [DiscountService, PricingRuleService, DaAppInstallService, tpsDaAppInstallService.DaAppInstallService],
})
export class PricingDiscountsUpsertComponent
  implements OnInit, CanDeactivateGuard {

  ADDED_MSG = 'The new rule was successfully added.';
  UPDATED_MSG = 'The rule was successfully updated.';
  DELETED_MSG = 'Rule has been deleted';

  company: Company;
  action: string;
  discountId: string;
  companyId: string;
  translations: string[];
  form: UntypedFormGroup;
  subTitle: string;
  types = ['percentage', 'fixed'];

  // Determines whether it's a discount or addition
  modifier: 1 | -1 = 1;

  discount: Discount = new Discount();
  checkArrayMeter = [];
  checkArrayFixed = [];

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _discountService: DiscountService,
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _appService: tpsDaAppInstallService.DaAppInstallService,
    private _daAppInstallService: DaAppInstallService,
    private _snackbar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private _translateService: TranslateService,
    private _titleService: Title,
    private _navigationService: NavigationService
  ) {
    _translateService.get(['addition', 'discount'])
      .subscribe((translations: any) => {
        this.translations = translations;
      });

    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(company);
    this.company = company;
  }

  /**
   * On component initialize.
   */
  ngOnInit() {
    this._route.parent.params.subscribe(first => {
      this.companyId = first['id'];
      this._route.params.subscribe(second => {
        this.discountId = second['id'];
        this.action = second['action'];

        if (Action['add'] === Action[this.action]) {
          this.initForm(this.discount);
        } else {
          this.startLoader();
          this.loadData();
        }
      });
    });
  }

  /**
   * When modifier changes.
   */
  onModifierChange({value}): void {
    this.form.controls.value.markAsTouched();
    this.form.controls.value.markAsDirty();
    if (value !== 1 && value !== -1) {
      throw Error('Input must be 1 or -1!');
    }
    this.modifier = value;
  }

  /**
   * Get name of type of discount depending on modifier.
   */
  getModifierName = (modifier: number): string =>
    modifier >= 0
      ? this._translateService.instant('addition')
      : this._translateService.instant('discount');

  /**
   * If input element value is not set, make it zero.
   */
  valOrZero(input): void {
    if (!input.value) {
      input.value = 0.00;
    }
    if (this.form.value.type === 'percentage') {
      return;
    }
    input.value = parseFloat(input.value).toFixed(2);
  }

  /**
   * Guard checks whether component can be deactivated.
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.pristine) {
      return true;
    }
    return this._dialogService.openConfirm({
      message: this._translateService.instant('confirm_leave'),
      disableClose: false,
      title: this._translateService.instant('unsaved_changes'),
      acceptButton: this._translateService.instant('leave'),
      cancelButton: this._translateService.instant('stay'),
    }).afterClosed();
  }

  /**
   * Load data from the api, sorting into different types.
   */
  loadData = () => this._discountService.get(this.discountId, {
    where: {companyId: this.companyId},
    include: [
      {
        relation: 'destination',
      },
      {
        relation: 'departure',
      },
    ]
  }).pipe(
    finalize(() => this.stopLoader()))
    .subscribe((discount) => {
      this.discount = discount;
      this.initForm(this.discount);
    })

  /**
   * Initializes form.
   */
  initForm(discount: Discount) {
    // See whether it's a true discount, or actually an addition
    const isPositive = x => x >= 0;
    this.modifier = isPositive(discount.value) ? 1 : -1;
    discount.value = Math.abs(discount.value);
    this.form = this.makeFormFor(discount);
    this.form.controls.type.valueChanges.subscribe(type => {
      this.form.controls.type.markAsTouched();
      this.form.controls.type.markAsDirty();
      this.form.controls.value.markAsTouched();
      this.form.controls.value.markAsDirty();

      if (type === 'percentage') {
        this.form.patchValue({
          value: parseFloat(this.form.value.value).toFixed(0)
        });
      }

      if (type === 'fixed') {
        this.form.patchValue({
          value: parseFloat(this.form.value.value).toFixed(2)
        });
      }
    })
  }

  /**
   * Persist Discount.
   */
  save() {
    if (this.form.pristine) {
      return;
    }

    this.checkArrayFixed = this.checkArrayFixed.filter((f) => {
      return (f)
    });

    if (this.checkArrayFixed.length === 0 && this.checkArrayMeter.length === 0) {
      this._dialogService.openAlert({
        title: this._translateService.instant('error'),
        message: this._translateService.instant('error_no_app_selected'),
        disableClose: true,
        closeButton: this.translations['ok'],
      });
      return;
    }

    const data = this.form.value;
    this.form.markAsPristine();

    if (data.ruleLocation === 'noLimit') {
      delete data.ruleLocation;
    }

    if (Action[this.action] === Action['add']) {
      this.insert(data);
    } else {
      this.update(data);
    }

    this.checkArrayFixed.forEach((install) => {
      this._appService.updateRelation(
        install, this.discount, 'discount',
      ).subscribe(() => {
        // const msg = event.checked
        //   ? this.DISCOUNT_ADDED_MSG
        //   : this.DISCOUNT_DELETED_MSG;
        // this._snackbar.open(msg, '', {duration: 3000});
      });
    });
  }

  /**
   * Insert Discount.
   */
  insert(data: any) {
    this.startLoader();

    data.companyId = this.companyId;
    data.value *= this.modifier;

    if (data.type === 'fixed') {
      data.value = toCents(data.value);
    }

    this._discountService.insert(data)
      .subscribe(result => {
        this._snackbar.open(this.ADDED_MSG, '', {duration: 3000});
        this._router.navigate([
          `/groups/${this.companyId}/pricing/special-rates`
        ]);
        this.stopLoader();
      });
  }

  /**
   * Update Discount.
   */
  update(data: any) {
    this.startLoader();

    data.companyId = this.companyId;
    data.value *= this.modifier;

    if (data.type === 'fixed') {
      data.value = toCents(data.value);
    }

    this._discountService.getDiscountLinks({
      where: {
        discountId: this.discountId
      }
    }).subscribe((links) => {
      if (links) {
        links.forEach((link) => {
          if (link.discountableType === 'DaAppInstall' && !this.checkArrayFixed.includes(link.discountableId)) {
            this._discountService.deleteDiscountLink(link._id).subscribe(() => {

            });
          }
        })
      }
    });

    this._discountService.update(data)
      .subscribe(result => {
        this._snackbar.open(this.UPDATED_MSG, '', {duration: 3000});
        this.stopLoader();
      });
  }

  /**
   * Delete Discount.
   */
  delete() {
    this._dialogService.openConfirm({
      message: 'Are you sure you wish to delete this special rate?',
      title: 'Delete special rate',
      acceptButton: 'Delete',
      cancelButton: 'Cancel',
      disableClose: true,
    }).afterClosed().subscribe(confirm => {
      if (confirm) {
        this.startLoader();
        this._discountService.delete(this.discountId).subscribe(() => {
          this._snackbar.open(this.DELETED_MSG, '', {duration: 3000});
          this._router.navigate([
            `/groups/${this.companyId}/pricing/special-ratings`
          ]);
          this.stopLoader();
        });
      }
    });
  }

  /**
   * Checks if form is touched, then checks if properties are touched,
   * then collect everything that is touched.
   */
  getTouchedFrom = (form: UntypedFormGroup | UntypedFormArray) => {
    // Base case, just the value
    if (!form.controls) {
      return form.value;
    }

    const touched = {};

    // Something may have changed somewhere down the tree
    if (form.touched) {
      // Add id if present
      if (form.controls['_id']) {
        touched['_id'] = form.controls['_id'].value;
      }

      // Iterate keys and see which of them are dirty recursively
      for (const f of Object.keys(form.controls)) {
        // If not dirty, continue
        if (form.controls[f].pristine) {
          continue;
        }
        // Else, get touched recursively
        let val = this.getTouchedFrom(form.controls[f]);
        // If it's a price, convert to cents
        if (f === 'value' && this.form.value.type === 'fixed') {
          val = toCents(val);
        }
        // Add value to result
        touched[f] = val;
      }
    }

    return touched;
  }

  /**
   * Makes a form for a given discount.
   */
  makeFormFor = (discount: Discount): UntypedFormGroup => this._fb.group({
    _id: [discount._id],
    name: [discount.name, [Validators.required]],
    type: [discount.type, [Validators.required]],
    ruleLocation: [discount.ruleLocation, [Validators.required]],
    priority: [discount.priority, [Validators.required, Validators.min(1)]],

    // value can store either percentages or cents, so it has to be
    // converted using functions toCents and fromCents
    value: [
      discount.type === 'fixed'
        ? fromCents(discount.value)
        : discount.value,
      [Validators.required, Validators.min(0)]],

    destinationId: [discount.destinationId],
    departureId: [discount.departureId],

    timeframes: this._fb.array(
      discount.timeframes.map(timeframe => this._fb.group({
        _id: [timeframe._id],
        startDate: [timeframe.startDate, [Validators.required]],
        endDate: [timeframe.endDate, [Validators.required]],
        weekSchedule: [timeframe.weekSchedule,
          [Validators.required, Validators.pattern(/^[0,1]{168}/)]],
        isSpecificWeekDays: [timeframe.isSpecificWeekDays, [Validators.required]]
      }))
    )
  });


  updateCheckArrayMeter(e) {
    this.checkArrayMeter = e.checkArrayMeter;
    if (!e.loading) {
      this.form.markAsDirty();
    }
  }

  updateCheckArrayFixed(e) {
    this.checkArrayFixed = e.checkArrayFixed;
    if (!e.loading) {
      this.form.markAsDirty();
    }
  }

  /**
   * Start the spinning loader.
   */
  startLoader = () => this._loadingService.register('discount')

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => this._loadingService.resolve('discount')

  /**
   * Disables form submission.
   */
  private formDisabled = (): boolean =>
    !this.form
    || !this.form.dirty
    || !this.form.valid;
}
