import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {Observable} from 'rxjs';
import {CanDeactivateGuard} from '../../../../../../guards/can-deactivate-guard';
import {Product, VehicleTypes} from '../../../../../../models/product';
import {ProductService} from '../../../../../../services/tps/product.service';
import {NavigationService} from '../../../../../../services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProductSelectImageDialogComponent} from './parts/product-select-image-dialog/product-select-image-dialog.component';

enum Action {
  add,
  details,
}

@Component({
  selector: 'app-product-upsert',
  templateUrl: './product-upsert.component.html',
  styleUrls: ['./product-upsert.component.scss'],
  providers: [ProductService]
})
export class ProductUpsertComponent implements OnInit, CanDeactivateGuard {
  ADDED_MSG = 'The new product was successfully added.';
  UPDATED_MSG = 'The product was successfully updated.';
  DELETED_MSG = 'Product has been deleted';

  action: string;
  productId: string;
  companyId: string;
  product: Product = new Product();
  form: UntypedFormGroup;
  types: string[];
  translations: string[];
  checkArray = [];
  dialogData: any;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _productService: ProductService,
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _snackbar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<ProductUpsertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this._route.routeConfig) {
      this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
    }

    if (data && data['productId']) {
      this.dialogData = data;
    }

    _translateService.get(['confirm_leave', 'unsave_changes', 'leave', 'stay']).subscribe((translations: any) => {
      this.translations = translations;

    });

    this.types = Object.keys(VehicleTypes)
      .map(k => VehicleTypes[k])
      .filter(v => typeof v !== 'number');
  }

  /**
   * On component initialize.
   */
  ngOnInit() {
    if (this._route.routeConfig) {
      // Get company Id
      this._route.parent.params.subscribe(first => {
        this.companyId = first['id'];
        // Get product Id and action
        this._route.params.subscribe(second => {
          this.productId = second['id'];
          this.action = second['action'];

          if (Action['add'] === Action[this.action]) {
            this.initForm();
          } else {
            this.startLoader();
            this.loadData();
          }
        });
      });
    } else {
      this.productId = this.dialogData['productId'];
      this.companyId = this.dialogData['companyId'];
      this.action = this.dialogData['action'];
      if (Action['add'] === Action[this.action]) {
        this.initForm();
      } else {
        this.startLoader();
        this.loadData();
      }
    }
  }

  /**
   * Guard checks whether component can be deactivated.
   */
  canDeactivate(): Observable<boolean> | boolean {
    if (this.form.pristine) {
      return true;
    }
    return this._dialogService.openConfirm({
      message: this.translations['confirm_leave'],
      disableClose: false,
      title: this.translations['unsave_changes'],
      acceptButton: this.translations['leave'],
      cancelButton: this.translations['stay'],
    }).afterClosed();
  }

  /**
   * Load data from the api, sorting into different types.
   */
  loadData = () => this._productService.get(this.productId, {
    where: {companyId: this.companyId}
  }).subscribe((product: Product) => {
    this.product = product;
    this.product.imagePath = (product.imagePath ? `${product.imagePath}?${this.randString()}` : null);
    if (this.product.dispatchTypes) {
      this.checkArray = this.product.dispatchTypes;
    }
    this.initForm();
    this.stopLoader();
  });

  makeRandom(lengthOfCode: number, possible: string) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  randString() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const lengthOfCode = 40;
    return this.makeRandom(lengthOfCode, possible)
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        image: file
      });
    }
  }

  /**
   * Initializes form.
   */
  initForm() {
    this.form = this.makeFormFor(this.product);
    this.form.valueChanges.subscribe(data => this.handleForm(data));
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.checkArray.push(e.target.value);
    } else {
      this.checkArray.forEach((item: string, i: number) => {
        if (item === e.target.value) {
          delete this.checkArray[i];
          return;
        }
      });
    }
  }

  /**
   * Persist Product.
   */
  save() {
    if (this.form.pristine) {
      return;
    }
    const data = this.form.value;
    this.form.markAsPristine();
    // data.dispatchTypes = this.checkArray;
    data.companyId = this.companyId;
    data.type = data.dispatchTypes[0];

    if (Action[this.action] === Action['add']) {
      data.priority = 100;
      this.insert(data);
    } else {
      this.update(data);
    }
  }

  /**
   * Create new Product.
   */
  insert(data: any) {
    this.startLoader();
    data.companyId = this.companyId;
    this._productService.insert(data).subscribe(result => {
      if (this.dialogData) {
        this.dialogData.parent.loadData();
        this.dialogData.parent.addServiceFromNewProduct(result._id);
        this.dialogRef.close();
      } else {
        this._snackbar.open(this.ADDED_MSG, '', {duration: 3000});
        this._router.navigate([`/groups/${this.companyId}/products`]);
        this.stopLoader();
      }
    });
  }

  /**
   * Update Product.
   */
  update(data: any) {
    const self = this;
    this.startLoader();

    if (data.description === '') {
      delete data.description;
    }
    if (data.extraInfo === '') {
      delete data.extraInfo;
    }
    delete data.image;

    this._productService.update(this.productId, data).subscribe(result => {
      this.product = result;
      if (self.form.get('image').value) {
        delete data.imagePath;
        self.uploadImage();
      } else {
        if (self.dialogData) {
          self.dialogData.parent.loadData();
          self.dialogRef.close();
        } else {
          self._snackbar.open(this.UPDATED_MSG, '', {duration: 3000});
          self._router.navigate([`/groups/${this.companyId}/products`]);
          self.stopLoader();
        }
      }
    });
  }

  uploadImage() {
    const self = this;
    const formData = new FormData();
    formData.append('file', this.form.get('image').value);
    if (self.form.get('image').value) {
      self._productService.upload(self.product._id, formData).subscribe(() => {
        setTimeout(function () {
          if (self.dialogData) {
            self.dialogData.parent.loadData();
            self.dialogRef.close();
          } else {
            self._snackbar.open(self.UPDATED_MSG, '', {duration: 3000});
            self._router.navigate([`/groups/${self.companyId}/products`]);
            self.stopLoader();
          }
        }, 500);
      }, () => {
        if (self.dialogData) {
          self.dialogData.parent.loadData();
          self.dialogRef.close();
        } else {
          self._snackbar.open(self.UPDATED_MSG, '', {duration: 3000});
          self._router.navigate([`/groups/${self.companyId}/products`]);
          self.stopLoader();
        }
      });
    }
  }

  /**
   * Delete product.
   */
  delete() {
    this._dialogService.openConfirm({
      message: 'Are you sure you wish to delete this product?',
      title: 'Delete product',
      acceptButton: 'Delete',
      cancelButton: 'Cancel',
      disableClose: true,
    }).afterClosed()
      .subscribe(
        confirm => {
          if (confirm) {
            this.startLoader();
            this._productService.delete(this.productId).subscribe(() => {
              this._snackbar.open(this.DELETED_MSG, '', {duration: 3000});
              this._router.navigate([`/groups/${this.companyId}/products`]);
              this.stopLoader();
            });
          }
        });
  }

  /**
   * Handles changes to form inputs.
   */
  handleForm(data?: {}) {
    //
  }

  /**
   * Makes a form for a given product.
   */
  makeFormFor = (product: Product): UntypedFormGroup => this._fb.group({
    name: [product.name, [Validators.required]],
    // type: [product.type, [Validators.required]],
    dispatchTypes: [product.dispatchTypes, [Validators.required]],
    maxPassengers: [product.maxPassengers, [Validators.required]],
    maxLuggage: [product.maxLuggage, [Validators.required]],
    extraInfo: [(product['extraInfo'] ? product['extraInfo'] : ''), []],
    description: [(product['description'] ? product['description'] : ''), []],
    image: [null, []],
    imagePath: [(product['imagePath'] ? product['imagePath'] : null), []],
  });

  /**
   * Start the spinning loader.
   */
  startLoader = () => this._loadingService.register('product')

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => this._loadingService.resolve('product')

  /**
   * Disables form submission.
   */
  private formDisabled = (): boolean =>
    !this.form
    || !this.form.dirty
    || !this.form.valid;

  selectDefaultimage() {
    /**
     * Open popup to select 1 default image
     */
    const dialogRef = this._dialogService.open(ProductSelectImageDialogComponent, {
      width: '500px',
      height: 'auto',
      data: {
        companyId: this.companyId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.form.get('imagePath').setValue(result);
        this.form.markAsDirty();
      }
    });
  }
}
