<mat-card-content>
  <mat-card-title>{{ 'cancellation_reason_dialog_title' | translate | ucFirst }}</mat-card-title>
  <div class="td-dialog-content">
    <span class="label">{{ 'cancellation_reason'|translate|ucFirst }}</span>
    <mat-form-field>
      <mat-select [(ngModel)]="cancellationReason">
        <mat-option *ngFor="let filter of cancelationReasons" [value]="filter">
          {{ 'cancellation_reason_' + filter|translate|ucFirst }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-list-item flex-gt-sm="100">
      <mat-form-field class="full-width">
        <mat-label>{{ 'cancellation_reason_note'|translate|ucFirst }}</mat-label>
        <textarea aria-multiline="true" [(ngModel)]="note" matInput></textarea>
      </mat-form-field>
    </mat-list-item>
  </div>

  <mat-divider></mat-divider>

  <mat-card-actions>
    <span class="td-dialog-spacer"></span>
    <button (click)="cancelJob()" color="warn"
            mat-raised-button>
      <span>{{ 'cancel_job' | translate | ucFirst }}</span>
    </button>

    <button (click)="dialogRef.close()"
            mat-raised-button>
      <span>{{ 'close' | translate | ucFirst }}</span>
    </button>
  </mat-card-actions>
</mat-card-content>
