import {Component, OnInit} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment-timezone';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../../../models/company';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {WebbookerService} from '../../../services/webbooker.service';
import {DaAppInstallService} from '../../../services/da-app-install.service';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CompanyService} from '../../../services/company.service';
import {PricingRuleService} from '../../../services/tps/pricing-rule.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TdDialogService} from '@covalent/core/dialogs';
import {User} from '../../../models/user';
import {ContractService} from '../../../services/contract.service';
import {IntegrationService} from '../../../services/integration.service';
import {WorkspaceSelectionComponent} from '../../portal/workspace-selection/workspace-selection.component';

@Component({
  selector: 'app-onboarding-wizard',
  templateUrl: './onboarding-wizard.component.html',
  styleUrls: ['./onboarding-wizard.component.scss'],
  providers: [PricingRuleService]
})
export class OnboardingWizardComponent implements OnInit {
  company: Company;
  form: UntypedFormGroup;
  user: User;
  environment = environment;
  step;
  // step='webbooker';
  webBookerDaAppInstall: any;
  webBooker: any;
  activeContract: any;
  translations: string[];
  // step='availability';
  // step='calendar';
  // step='service';
  // step='completed';

  constructor(
    private _formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private _vault: CoolLocalStorage,
    private _router: Router,
    private _translateService: TranslateService,
    private _snackBar: MatSnackBar,
    private _daAppInstall: DaAppInstallService,
    private _webbookerService: WebbookerService,
    private _dialogService: TdDialogService,
    private _companyService: CompanyService,
    private _contractService: ContractService,
    private _integrationService: IntegrationService,
    private _pricingRuleService: PricingRuleService,
  ) {
    const self = this;
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);

    this._translateService.get(['saved_servicearea_details', 'saved_webbooker_details', 'saved_availability_details', 'saved_calendar_details', 'ok', 'cancel', 'confirm_skipp_wizard', 'yes', 'no', 'new_feature_wizard_title', 'new_feature_wizard_text']).subscribe((translations: any) => {
      this.translations = translations;

      let companyId;
      const {company} = this._route.parent.snapshot.data;
      if (company) {
        this.company = company;
        companyId = this.company.id;
        this.setCompanyDetails();
      } else {
        this._route.params.subscribe(params => {
          companyId = params['id'];

          // const savedStep = this._vault.getObject(`${environment.vaultPrefix}.wizard.step`);
          this._companyService.get(companyId).subscribe(c => {
            this.company = c;
            this.setCompanyDetails(function () {
              if (params['step'] !== 'wizard') {
                self.step = (params['step'] ? params['step'] : 'webbooker');
              } else {
                self.step = (self.company.settings.wizard && self.company.settings.wizard !== 'skipped' ? self.company.settings.wizard : 'webbooker');
              }
            });
          });
        });
      }
    });
  }

  setCompanyDetails(cb: any = null): void {
    const self = this;

    if (!this.company.settings.availability) {
      this.company.settings.availability = {};
    }

    if (!this.company.settings.availability.availabilityStart) {
      this.company.settings.availability.availabilityStart = '09:00';
    }
    if (!this.company.settings.availability.availabilityEnd) {
      this.company.settings.availability.availabilityEnd = '17:00';
    }
    if (!this.company.settings.availability.minimumOrderTime) {
      this.company.settings.availability.minimumOrderTime = 30;
    }
    if (!this.company.settings.availability.availableDays || this.company.settings.availability.availableDays['day_mon']) {
      this.company.settings.availability.availableDays = {
        'mon': [{'active': true}],
        'thu': [{'active': true}],
        'wed': [{'active': true}],
        'thr': [{'active': true}],
        'fri': [{'active': true}],
        'sat': [{'active': false}],
        'sun': [{'active': false}],
      };
    } else if (this.company.settings.availability.availableDays['mon'] && this.company.settings.availability.availableDays['mon'].start) {
      Object.keys(this.company.settings.availability.availableDays).forEach((day) => {
        this.company.settings.availability.availableDays[day] = [this.company.settings.availability.availableDays[day]];
      })
    }


    if (!this.company.settings.availability.minimumOrderTime) {
      this.company.settings.availability.minimumOrderTime = 30;
      this.company.settings.availability.minimumOrderTimeFormat = 'minutes';
    }

    if (this.company.settings.availability.minimumOrderTimeFormat === 'hours') {
      this.company.settings.availability.minimumOrderTime = (this.company.settings.availability.minimumOrderTime / 60);
    }

    if (!this.company.settings.calendar) {
      this.company.settings.calendar = {};
    }

    this._daAppInstall.getAll({where: {daAppId: environment.webbookerDaAppId, companyId: this.company.id}})
      .subscribe((d) => {
        if (!d[0]) {
          self._integrationService.installOrderForm(self.company.id)
            .subscribe(() => {
              self.setCompanyDetails();
            });
          return;
        }

        const formConfig = {
          webbookerUrl: [],
          timezone: [],
          radius: [],
          area: [],
          minimumOrderTime: [this.company.settings.availability.minimumOrderTime, [Validators.required]],
          minimumOrderTimeFormat: [(this.company.settings.availability.minimumOrderTimeFormat ? this.company.settings.availability.minimumOrderTimeFormat : 'minutes'), [Validators.required]],
          availabilityStart: [this.company.settings.availability.availabilityStart, [Validators.required]],
          availabilityEnd: [this.company.settings.availability.availabilityEnd, [Validators.required]],
          allDay: [this.company.settings.availability.allDay, []],
          availableDays: this._formBuilder.group({
            'mon': this.company.settings.availability.availableDays['mon'][0].active,
            'thu': this.company.settings.availability.availableDays['thu'][0].active,
            'wed': this.company.settings.availability.availableDays['wed'][0].active,
            'thr': this.company.settings.availability.availableDays['thr'][0].active,
            'fri': this.company.settings.availability.availableDays['fri'][0].active,
            'sat': this.company.settings.availability.availableDays['sat'][0].active,
            'sun': this.company.settings.availability.availableDays['sun'][0].active,
          }),
          checkConflicts: [(this.company.settings.calendar && typeof (this.company.settings.calendar.checkConflicts) !== 'undefined' ? this.company.settings.calendar.checkConflicts : false), []],
          addEvents: [(this.company.settings.calendar.addEvents && typeof (this.company.settings.calendar.addEvents) !== 'undefined' ? this.company.settings.calendar.addEvents : false), []],
          googleAuthorization: [(this.company.settings && this.company.settings.calendar && this.company.settings.calendar.authorization ? this.company.settings.calendar.authorization : null), []],
          primary: [(this.company.settings && this.company.settings.calendar && this.company.settings.calendar.primary ? this.company.settings.calendar.primary : null), []],
          addService: ['select', []],
        };

        this.webBookerDaAppInstall = d[0];

        if (this.webBookerDaAppInstall && !this.webBookerDaAppInstall.formId) {
          self._router.navigate([`/groups/${self.company.id}/webbookers`]);
        }

        if (this.webBookerDaAppInstall && this.webBookerDaAppInstall.formId) {
          this._webbookerService.get(this.webBookerDaAppInstall.formId).subscribe((webbooker) => {
            this.webBooker = webbooker;
            this.webBooker.url = `${environment.orderFormFrontEndUrl}/${this.user.locale.toLowerCase()}/${(this.webBooker.config.customUrl ? this.webBooker.config.customUrl : this.webBooker.id)}`;


            if (!this.webBooker.config.customUrl) {
              this.webBooker.config.customUrl = this.company.name.replace(/[^a-zA-Z0-9]+/g, '').toLowerCase();
            }

            formConfig.webbookerUrl = [this.webBooker.config.customUrl, [Validators.required]];
            formConfig.timezone = [(this.webBooker.config.timeZone ? this.webBooker.config.timeZone : moment.tz.guess()), [Validators.required]];

            this.form = this._formBuilder.group(formConfig);

            if ((!this.company.settings || !this.company.settings.wizard) && moment(this.company.created).isBefore(new Date('2023-05-01T00:00:00Z'))) {
              this._dialogService.openAlert({
                title: self.translations['new_feature_wizard_title'],
                message: self.translations['new_feature_wizard_text'],
                disableClose: true,
                closeButton: self.translations['ok'],
              }).afterClosed().subscribe(() => {
              });
            }

            if (cb) {
              return cb();
            } else {
              return;
            }
          });
        }
      });
  }

  ngOnInit(): void {

  }

  nextStep(step: any): void {
    const self = this;
    if (step !== 'skipped') {
      self._companyService.get(self.company.id)
        .subscribe((c) => {
          self.company = c;
          self.company.settings.wizard = step;

          if (step === 'done') {
            self._router.navigate([`/groups/${self.company.id}/dispatchpanel`]);
          } else if (step === 'servicearea') {
            self.step = step;
            self.saveWebbookerSettings();
          } else if (step === 'availability') {
            self.step = step;
            self.saveCompanyDetails('saved_servicearea_details');
          } else if (step === 'calendar') {
            self.step = step;
            self.saveAvailabilitySettings();
          } else if (step === 'service') {
            self.step = step;
            self.saveCompanyDetails('saved_calendar_details');
          } else if (step === 'completed') {
            self.step = step;
            self.saveCompanyDetails('saved_services_details');
          }

          /**
           * Update AC Deal
           */
          if (this.activeContract) {
            self._contractService.update(this.activeContract.id, {'wizard': this.step}).subscribe(() => {
            });
          } else {
            self._companyService.get(this.company.id, {include: ['ActiveContract']})
              .subscribe((company) => {
                if (company.ActiveContract) {
                  this.activeContract = company.ActiveContract;
                  self._contractService.update(this.activeContract.id, {'wizard': this.step}).subscribe(() => {
                  });
                }
              });
          }
        })
    } else {
      self._dialogService.openConfirm({
        message: self.translations['confirm_skipp_wizard'],
        disableClose: false,
        cancelButton: self.translations['no'],
        acceptButton: self.translations['yes'],
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.step = step;
          this._vault.setItem('wizard_skipped', 'true');
          this._router.navigate([`/groups/${this.company.id}/dispatchpanel`]);
        }
      });
    }
  }

  saveCompanyDetails(msg, cb?: () => void) {
    const self = this;
    self._companyService.update(this.company.id, this.company).subscribe(() => {
      self._snackBar.open(ucFirst(self._translateService.instant(msg)), self.translations['ok'], {
        duration: 3000
      });
      if (cb) {
        cb();
      }
    }, error => {

    });
  }

  saveWebbookerSettings() {
    const self = this;
    if (self.webBookerDaAppInstall) {
      self.webBooker.config.customUrl = self.form.controls['webbookerUrl'].value;
      self.webBooker.config.timeZone = self.form.controls['timezone'].value;
      self._webbookerService.update(self.webBookerDaAppInstall.formId, self.webBooker).subscribe(() => {
        self.saveCompanyDetails('saved_webbooker_details');
      }, error => {

      });
    }
  }

  saveAvailabilitySettings() {
    const self = this;
    if (self.form.controls['minimumOrderTimeFormat'].value === 'hours') {
      self.form.controls['minimumOrderTime'].setValue(self.form.controls['minimumOrderTime'].value * 60);
    }

    if (self.form.controls['allDay'].value) {
      self.form.controls['availabilityStart'].setValue('00:00');
      self.form.controls['availabilityEnd'].setValue('00:00');
    }

    /**
     * Preform mapping to correct format for days
     */
    const availabilityDays = {};
    Object.keys(self.form.controls['availableDays'].value).forEach((v) => {
      availabilityDays[v] = [{
        'active': self.form.controls['availableDays'].value[v],
        'start': self.form.controls['availabilityStart'].value,
        'end': self.form.controls['availabilityEnd'].value
      }];
    });

    this.company.settings.availability = {
      availabilityStart: self.form.controls['availabilityStart'].value,
      availabilityEnd: self.form.controls['availabilityEnd'].value,
      allDay: self.form.controls['allDay'].value,
      availableDays: availabilityDays,
      minimumOrderTime: self.form.controls['minimumOrderTime'].value,
      minimumOrderTimeFormat: self.form.controls['minimumOrderTimeFormat'].value,
    };

    this.saveCompanyDetails('saved_availability_details');
  }

  saveServiceSettings(data) {
    const self = this;

  }

  switchWorkspace(): void {
    this._dialogService.open(WorkspaceSelectionComponent, {
      maxHeight: '80%',
      minWidth: (window.innerWidth < 500 ? '95%' : '500px'),
      data: {
        openFromDialog: true,
        parent: this
      },
    });
  }
}
