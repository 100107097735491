import {Component, Inject, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {CompanyService} from '../../../../../services/company.service';
import {ContractService} from '../../../../../services/contract.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from '../../../../../models/company';
import {IntegrationService} from '../../../../../services/integration.service';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-purchase-webbooker',
  templateUrl: './purchase-webbooker.component.html',
  styleUrls: ['./purchase-webbooker.component.scss']
})
export class PurchaseWebbookerComponent implements OnInit {
  company: Company;
  parent: any;
  translations: string[];
  webbookerAmount = {
    total: 2000,
    currency: 'EUR'
  }

  constructor(
    private _companyService: CompanyService,
    private _contractService: ContractService,
    private _integrationService: IntegrationService,
    private _loadingService: TdLoadingService,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<PurchaseWebbookerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.company = data.parent.company;
    this.parent = data.parent;
    this.translations = data.parent.translations;
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  shouldAddWebbookerToContract(): boolean {
    if (this.parent.company.ActiveContract && this.parent.company) {
      if (this.parent.company.ActiveContract.taxiIDContract) {
        return false;
      }

      const contractWebbookers = this.parent.company.ActiveContract.ContractProducts.filter((p) => {
        return (p.sku === 'webbooker' ? p : null)
      })[0];

      if (!contractWebbookers) {
        return true;
      } else if (this.parent.webbookers.length < (contractWebbookers.quantity + 1)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  addWebbooker(): void {
    const self = this;
    self.closeDialog();
    self._loadingService.register();
    if (self.shouldAddWebbookerToContract()) {
      this._contractService.addProduct(this.company.ActiveContract.id, {
        'sku': 'webbooker',
        'stripePriceId': environment.webbookerPriceId
      }).subscribe((result) => {
        if (result.error) {
          this._snackbar.open(this.translations['webbooker_added_error'], '', {duration: 3000});
        } else {
          this._integrationService.installOrderForm(this.company.id)
            .subscribe(() => {
              self._loadingService.resolveAll();
              setTimeout(function () {
                self._snackbar.open(self.translations['webbooker_added_message'], '', {duration: 3000});
                self.data.parent.loadData();
              }, 2500);
            })
        }
      })
      return;
    } else {
      this._integrationService.installOrderForm(this.company.id)
        .subscribe(() => {
          this._snackbar.open(this.translations['webbooker_added_message'], '', {duration: 3000});
          setTimeout(function () {
            self.data.parent.loadData();
          }, 2500);
        })
    }
  }
}
